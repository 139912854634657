/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import * as util from './common';
var Store = {
  debug: process.env.NODE_ENV !== 'production',
  state: {
    serverId: null,
    //人脸照片
    locationId: null,
    //定位考勤
    outSideId: null,
    //外勤
    outSideTime: 0,
    //外勤时间
    locationTime: 0,
    //定位考勤时间
    faceIndex: null,
    visBh: null,
    //参会Bh
    token: null,
    //请求头token
    reSeting: 1,
    //默认开启允许修改人脸档案
    dangan: 1,
    //开启档案上传
    renlian: 1 //开启人脸上传

  },
  set: function set(key, newValue) {
    var _this = this;

    return new Promise(function (resolve, reject) {
      if (_this.state[key] !== void 0) {
        _this.state[key] = newValue;
        resolve(true);
      } else {
        reject("this key [".concat(key, "] is not register in store!"));
      }
    });
  },
  get: function get(key) {
    if (key && key.split) {
      if (this.state[key] === void 0) {
        util.warn("key [".concat(key, "] is not register in store!"));
      }

      return this.state[key];
    }
  },
  setItem: function setItem(key, value) {
    sessionStorage.setItem(key, value);
  },
  getItem: function getItem(key) {
    return sessionStorage.getItem(key);
  },
  removeItem: function removeItem(key) {
    sessionStorage.removeItem(key);
  },
  clear: function clear() {
    sessionStorage.clear();
  },
  setLocalItem: function setLocalItem(key, value) {
    localStorage.setItem(key, value);
  },
  getLocalItem: function getLocalItem(key) {
    return localStorage.getItem(key);
  },
  removeLocalItem: function removeLocalItem(key) {
    localStorage.removeItem(key);
  },
  clearLocalItem: function clearLocalItem() {
    localStorage.clear();
  }
};
export default Store;