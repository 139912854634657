/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Home = {
  getScanResult: function getScanResult(params) {
    return http.post("".concat(base.dev, "/ktJl/scanResult"), params);
  },
  getQrcode: function getQrcode(params) {
    return http.post("".concat(base.dev, "/user/getCode"), params);
  },
  getAccountInfo: function getAccountInfo(params) {
    return http.post("".concat(base.dev, "/xfMx/accountSum"), params);
  },
  getAccountRecords: function getAccountRecords(params) {
    return http.post("".concat(base.dev, "/xfMx/selectAccountRecord"), params);
  },
  recharge: function recharge(params) {
    return http.put("".concat(base.dev, "/account/payOrder"), params);
  },
  Srecharge: function Srecharge(params) {
    return http.post("http://pay.lcsw.cn/pay/100/jspay", params);
  },
  getRechargeRecord: function getRechargeRecord(params) {
    return http.post("".concat(base.dev, "/xfMx/selectAccountRecharge"), params);
  },
  getHomeMenu: function getHomeMenu(params) {
    return http.post("".concat(base.dev, "/userMenus/selectListByEntity"), params);
  },
  getResultDetail: function getResultDetail(params) {
    return http.post("".concat(base.dev, "/account/getResultDetail"), params);
  },
  getAllMenu: function getAllMenu(params) {
    return http.post("".concat(base.dev, "/systemMenus/selectListByEntity"), params);
  },
  saveMyMenu: function saveMyMenu(params) {
    return http.put("".concat(base.dev, "/userMenus/insert"), params);
  }
};
export default Home;