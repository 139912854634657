/* this file is transformed by vux-loader */
/* eslint-disable */
import "F:\\zhaoyanning\\\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662\\mobile-v2\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "F:\\zhaoyanning\\\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662\\mobile-v2\\node_modules\\core-js\\modules\\es.promise.js";
import "F:\\zhaoyanning\\\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662\\mobile-v2\\node_modules\\core-js\\modules\\es.object.assign.js";
import "F:\\zhaoyanning\\\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662\\mobile-v2\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.function.name.js";
//基础引用
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Utils from './utils/index.js'; // 调试控件
// import VConsole from "vconsole";
// if (process.env.NODE_ENV !== 'production') {
//     new VConsole()
// }

console.log('1.1.1'); // 接口注册

import API from './api/index.js';
Vue.use(API); //全局样式

import './style/global.scss'; // 全局参数

import Params from './api/global.js';
Vue.prototype.$params = Params; // 国际化

import './locales/index.js'; // vux 全局注册的插件

import confirmPlugin from 'vux/src/plugins/confirm';
import loadingPlugin from 'vux/src/plugins/loading';
import toastPlugin from 'vux/src/plugins/toast';
import datetimePlugin from 'vux/src/plugins/datetime';
Vue.use(confirmPlugin);
Vue.use(loadingPlugin);
Vue.use(toastPlugin);
Vue.use(datetimePlugin); // 引用微信sdk

import wx from 'weixin-js-sdk';
Vue.prototype.$wechat = wx; // 钉钉

import * as dd from 'dingtalk-jsapi';
Vue.prototype.$dd = dd; // VAnt UI库

import 'vant/lib/index.css';
import VAnt from 'vant';
Vue.use(VAnt); // 时间轴

import LightTimeline from 'vue-light-timeline'; // TODO: 此插件需要后续整理删除

Vue.use(LightTimeline); // 移动端手势事件的监听

import kimVueTouch from 'kim-vue-touch';
Vue.use(kimVueTouch); // 移动端手势事件的监听

import vueTouch from 'vue-touch'; // TODO: 此插件需要后续整理删除

Vue.use(vueTouch); // 滑动组件库

import VueAwesomeSwiper from 'vue-awesome-swiper'; // TODO: 此插件需要后续整理删除

Vue.use(VueAwesomeSwiper); // 动画组件

import animate from 'animate.css';
Vue.use(animate); // 处理移动端点击

import FastClick from 'fastclick';
FastClick.attach(document.body);
Vue.prototype.$dd = dd;
Vue.prototype.$utils = Utils;
Vue.prototype.$code = {
  success: 600,
  fail: 601,
  cancel: 602,
  unbound: 605
}; // ios点击不灵敏

FastClick.attach(document.body);

FastClick.prototype.focus = function (targetElement) {
  var length;

  if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
};

Vue.prototype.$constant = {
  msgShowLen: 'MESSAGE_SHOW_LENGTH',
  repairAttendanceType: 'REPAIR_ATTENDANCE_TYPE'
}; // vue 属性配置

Vue.config.productionTip = false; // 忽略对<wx-open-launch-weapp>标签的校验，删除后使用次标签会提示【未知的自定义元素】

Vue.config.ignoredElements = ['wx-open-launch-weapp'];
window.vm = new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');
var home = ['Index', 'Schedule', 'Linkman', 'My', 'Login', 'RegisterResult', 'InvalidCode'];
var detail = ['LeaveDetail', 'GoOutDetail', 'OverTimeDetail', 'ChangeShiftDetail', 'ContractDetail', 'RepairAttendanceDetail', 'DineHome', 'MeetingDetail', 'RepairDetail', 'RepairAppInfo', 'VisitorDetail', 'Attendances', 'LivingPage'];

window.onpopstate = function () {
  if (home.indexOf(window.vm.$route.name) > -1) {
    window.vm.$route.params['flag'] = false;
    Utils.Tools.getEnv(function (res) {
      if (res === 0) {
        window.vm.$wechat.miniProgram.navigateBack({
          delta: 1
        });
      } else if (res === 1) {
        window.WeixinJSBridge.call('closeWindow');
      } else {
        dd.biz.navigation.close();
      }
    });
  }

  if (detail.indexOf(window.vm.$route.name) > -1) {
    if (window.vm.$route.params['notification']) {
      router.push('/');
    }
  }
};