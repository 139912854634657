/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Overtime = {
  getOvertimeDetail: function getOvertimeDetail(params) {
    return http.post("".concat(base.dev, "/overTime/selectOverTimeByXh"), params);
  },
  insertOverTime: function insertOverTime(params) {
    return http.post("".concat(base.dev, "/overTime/insertOverTime"), params);
  },
  deleteOverTime: function deleteOverTime(xh, userSerial, reason) {
    return http.delete("".concat(base.dev, "/overTime/deleteOverTime"), {
      params: {
        xh: xh,
        userSerial: userSerial,
        reason: reason
      }
    });
  },
  approvalOverTime: function approvalOverTime(params) {
    return http.post("".concat(base.dev, "/overTime/approvalOverTime"), params);
  }
};
export default Overtime;