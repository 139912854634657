/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var My = {
  loginOut: function loginOut(params) {
    return http.post("".concat(base.dev, "/user/unbindRelation"), params);
  },
  deleteInfo: function deleteInfo(openId) {
    return http.delete("".concat(base.dev, "/companyUserInfo/deleteByPrimaryKey"), {
      params: {
        openId: openId
      }
    });
  },
  pushMessage: function pushMessage(params) {
    return http.post("".concat(base.dev, "/loginOpenid/updateSendState"), params);
  },
  selectSendState: function selectSendState(params) {
    return http.post("".concat(base.dev, "/loginOpenid/selectSendState"), params);
  },
  updatePwd: function updatePwd(params) {
    return http.post("".concat(base.dev, "/user/updatePwd"), params);
  },
  updateDefaultPwd: function updateDefaultPwd(params) {
    return http.post("".concat(base.dev, "/user/updateDefaultPwd"), params);
  },
  getCardState: function getCardState(params) {
    return http.post("".concat(base.dev, "/card/getCardState"), params);
  },
  getCardInfo: function getCardInfo(params) {
    return http.post("".concat(base.dev, "/user/getUserCardInfo"), params);
  },
  cardLoss: function cardLoss(params) {
    return http.post("".concat(base.dev, "/user/cardLoss"), params);
  },
  setWxHeadImage: function setWxHeadImage(params) {
    return http.post("".concat(base.dev, "/userInfo/setWxHeadImage"), params);
  },
  getWxHeadImage: function getWxHeadImage(userSerial) {
    return http.get("".concat(base.dev, "/userInfoExtend/selectByPrimaryKey"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  getUserHeadImage: function getUserHeadImage(userSerial) {
    return http.get("".concat(base.dev, "/user/getUserHeadImage"), {
      params: {
        userSerial: userSerial
      }
    });
  }
};
export default My;