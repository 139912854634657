/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Dine = {
  getMeals: function getMeals(params) {
    return http.post("".concat(base.dev, "/dineMeal/selectListByEntity"), params);
  },
  updateUserAutoFlag: function updateUserAutoFlag(params) {
    return http.post("".concat(base.dev, "/dineUser/updateByPrimaryKey"), params);
  },
  getUserAutoFlag: function getUserAutoFlag(userSerial) {
    return http.get("".concat(base.dev, "/dineUser/selectByPrimaryKey"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  getDineRemark: function getDineRemark() {
    return http.get("".concat(base.dev, "/dineGlobal/selectDineRemark"));
  },
  getUserOrderList: function getUserOrderList(params) {
    return http.post("".concat(base.dev, "/dineOrder/selectListByCondition"), params);
  },
  getUserOrderListPage: function getUserOrderListPage(params) {
    return http.post("".concat(base.dev, "/dineOrder/selectListPageByEntity"), params);
  },
  getDineNums: function getDineNums(params) {
    return http.post("".concat(base.dev, "/dineOrder/selectDineNums"), params);
  },
  updateDineNums: function updateDineNums(params) {
    return http.post("".concat(base.dev, "/dineOrder/updateByPrimaryKey"), params);
  }
};
export default Dine;