/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.split.js";
var RegExp = {
  checkEmpty: function checkEmpty(value) {
    //验证是否为空
    var reg = /^[\s\S]*.*[^\s][\s\S]*$/;

    if (!reg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkSpechars: function checkSpechars(value) {
    //验证是否为特殊字符
    var regEn = /[`~!@#$%^&*()_+<>?:"{}/;'[\]]/im,
        regCn = /[·！#￥（——）：；“”‘、|《》？、【】[\]]/im;

    if (regEn.test(value) || regCn.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkChars: function checkChars(value) {
    //验证字符为数字或字母
    var reg = /^[A-Fa-f0-9]*$/;

    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkPhone: function checkPhone(value) {
    //验证手机号码
    var reg = /^[1][3,4,5,7,8][0-9]{9}$/;

    if (reg.test(value)) {
      return false;
    } else {
      return true;
    }
  },
  checkIdCard: function checkIdCard(id) {
    //完证的验证身份证的
    // let regs = /^[a-zA-Z][a-zA-Z0-9]*$/;
    // if(regs.test(id)){
    //    this.Idflag=true
    //   return true
    // }
    // 1 "验证通过!", 0 //校验不通过
    var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/; //号码规则校验

    if (!format.test(id)) {
      // {'status':0,'msg':'身份证号码不合规'}
      return false;
    } //区位码校验
    //出生年月日校验   前正则限制起始年份为1900;


    var year = id.substr(6, 4),
        //身份证年
    month = id.substr(10, 2),
        //身份证月
    date = id.substr(12, 2),
        //身份证日
    time = Date.parse(month + '-' + date + '-' + year),
        //身份证日期时间戳date
    now_time = Date.parse(new Date()),
        //当前时间戳
    dates = new Date(year, month, 0).getDate(); //身份证当月天数

    if (time > now_time || date > dates) {
      // {'status':0,'msg':'出生日期不合规'}
      return false;
    } //校验码判断


    var c = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; //系数

    var b = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; //校验码对照表

    var id_array = id.split("");
    var sum = 0;

    for (var k = 0; k < 17; k++) {
      sum += parseInt(id_array[k]) * parseInt(c[k]);
    }

    if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
      // {'status':0,'msg':'身份证校验码不合规'}
      return false;
    } //  {'status':1,'msg':'校验通过'}


    return true;
  },
  checkCardNO: function checkCardNO(value) {
    //验证身份证号
    var reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/i; // let val =  value.replace(/x$/i, "a");

    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkCarNO: function checkCarNO(value) {
    //验证车牌号
    var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/; //新能源车规则

    var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/; //普通汽车规则

    if (xreg.test(value) || creg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkNumber: function checkNumber(value) {
    //验证数字
    var reg = /^\d{1,2}$/;

    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkIP: function checkIP(value) {
    //验证IP地址
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;

    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkQQ: function checkQQ(value) {
    //验证QQ
    var reg = /^[1-9][0-9]{4,9}$/;

    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkEmail: function checkEmail(value) {
    //验证邮箱
    var reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  checkName: function checkName(value) {
    //验证用户名或账号(只能是数字、字母和下划线中的至少2种，并且不能数字开头)
    var reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  }
};
export default RegExp;