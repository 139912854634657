/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var UserPower = {
  insert: function insert(params) {
    return http.put("".concat(base.dev, "/userPower/insert"), params);
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/userPower/updateByPrimaryKey"), params);
  },
  selectListPageByEntity: function selectListPageByEntity(params) {
    return http.post("".concat(base.dev, "/userPower/selectListPageByEntity"), params);
  },
  selectListByEntity: function selectListByEntity(params) {
    return http.post("".concat(base.dev, "/userPower/selectListByEntity"), params);
  },
  deleteByPrimaryKey: function deleteByPrimaryKey(userSerial) {
    return http.delete("".concat(base.dev, "/userPower/deleteByPrimaryKey"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  selectByPrimaryKey: function selectByPrimaryKey(userSerial) {
    return http.get("".concat(base.dev, "/userPower/selectByPrimaryKey"), {
      params: {
        userSerial: userSerial
      }
    });
  }
};
export default UserPower;