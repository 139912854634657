/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var ChangeShift = {
  getChangeShiftDetail: function getChangeShiftDetail(params) {
    return http.post("".concat(base.dev, "/moveShift/selectMoveShiftByXh"), params);
  },
  insertChangeShift: function insertChangeShift(params) {
    return http.post("".concat(base.dev, "/moveShift/insertMoveShift"), params);
  },
  deleteChangeShift: function deleteChangeShift(xh, userSerial, reason) {
    return http.delete("".concat(base.dev, "/moveShift/deleteMoveShift"), {
      params: {
        xh: xh,
        userSerial: userSerial,
        reason: reason
      }
    });
  },
  approvalChangeShift: function approvalChangeShift(params) {
    return http.post("".concat(base.dev, "/moveShift/approvalMoveShift"), params);
  },
  getBanci: function getBanci(params) {
    return http.post("".concat(base.dev, "/moveShift/getBanci"), params);
  }
};
export default ChangeShift;