/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var CompanyInfo = {
  insert: function insert(params) {
    return http.put("".concat(base.dev, "/companyInfo/insert"), params);
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/companyInfo/updateByPrimaryKey"), params);
  },
  selectListPageByEntity: function selectListPageByEntity(params) {
    return http.post("".concat(base.dev, "/companyInfo/selectListPageByEntity"), params);
  },
  selectListByEntity: function selectListByEntity(params) {
    return http.post("".concat(base.dev, "/companyInfo/selectListByEntity"), params);
  },
  deleteByPrimaryKey: function deleteByPrimaryKey(companyId) {
    return http.delete("".concat(base.dev, "/companyInfo/deleteByPrimaryKey"), {
      params: {
        companyId: companyId
      }
    });
  },
  selectByPrimaryKey: function selectByPrimaryKey(companyId) {
    return http.get("".concat(base.dev, "/companyInfo/selectByPrimaryKey"), {
      params: {
        companyId: companyId
      }
    });
  },
  selectListByKeys: function selectListByKeys(params) {
    return http.post("".concat(base.dev, "/companyInfo/selectListByKeys"), params);
  },
  deleteBatchByKeys: function deleteBatchByKeys(keys) {
    return http.delete("".concat(base.dev, "/companyInfo/deleteBatchByKeys"), {
      params: {
        keys: keys
      }
    });
  },
  getKeys: function getKeys() {
    return http.get("".concat(base.dev, "/common/getKeys"));
  },
  selectCompanyRight: function selectCompanyRight(companyId) {
    return http.get("".concat(base.dev, "/companyInfo/selectCompanyRight"), {
      params: {
        companyId: companyId
      }
    });
  },
  updateCompanyRight: function updateCompanyRight(params) {
    return http.post("".concat(base.dev, "/companyInfo/updateCompanyRight"), params);
  }
};
export default CompanyInfo;