/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Login = {
  getOpenId: function getOpenId(code, mode) {
    if (mode === '1') {
      return http.get("".concat(base.dev, "/wx/getOpenId"), {
        params: {
          code: code
        }
      });
    } else {
      return http.get("".concat(base.dev, "/user/getOpenId"), {
        params: {
          code: code
        }
      });
    }
  },
  getUserInfoByOpenId: function getUserInfoByOpenId(openId) {
    return http.get("".concat(base.dev, "/user/getUserInfoByOpenId"), {
      params: {
        openId: openId
      }
    });
  },
  setOpenId: function setOpenId(code, companyId) {
    return http.get("".concat(base.dev, "/user/setOpenId"), {
      params: {
        code: code
      },
      headers: {
        'CompanyId': companyId
      }
    });
  },
  getOpenIdByUnionId: function getOpenIdByUnionId(unionId, companyId) {
    return http.get("".concat(base.dev, "/user/getUserByUnionId"), {
      params: {
        unionId: unionId
      },
      headers: {
        'CompanyId': companyId
      }
    });
  },
  login: function login(params) {
    return http.post("".concat(base.dev, "/user/userLoginCheck"), params, {
      headers: {
        'CompanyId': params.companyId
      }
    });
  },
  // 获取是否为第一次和解除微信绑定的判断
  selectlogin: function selectlogin(params) {
    return http.post("".concat(base.dev, "/user/userLoginBefore"), params);
  },
  // 获取登录密钥
  getKey: function getKey(params) {
    return http.post("".concat(base.dev, "/user/key"), params);
  },
  // 获取是否打开验证码进行验证
  getyanzhen: function getyanzhen(params) {
    return http.post("".concat(base.dev, "/user/getOpenValid"), params);
  },
  // 获取验证码
  getverifications: function getverifications(params) {
    return http.post("".concat(base.dev, "/user/verification"), params);
  },
  updateInfo: function updateInfo(params) {
    return http.post("".concat(base.dev, "/companyUserInfo/updateInfo"), params);
  },
  insertUser: function insertUser(params) {
    return http.post("".concat(base.dev, "/userCustomized/insertUser"), params);
  },
  //访客接口
  getVisUser: function getVisUser(openId) {
    return http.get("".concat(base.dev, "/visUserNew/selectVisUserByOpenId"), {
      params: {
        openId: openId
      }
    });
  },
  //判断是否关注公众号
  getUnionIdByCode: function getUnionIdByCode(params) {
    return http.get("".concat(base.dev, "/user/getUnionIdByCode"), {
      params: params
    });
  },
  ///user/getUin
  getUin: function getUin() {
    return http.get("".concat(base.dev, "/user/getUin"));
  }
};
export default Login;