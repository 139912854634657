/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var futianVis = {
  getVisUserInfo: function getVisUserInfo(openId) {
    //根据id是否访客绑定了
    return http.post("".concat(base.dev, "/visFlow/getVisitorInfoByOpenId"), {
      openId: openId
    });
  },
  removeVisUserInfo: function removeVisUserInfo(openId) {
    //j解绑当前的访客信息
    return http.get("".concat(base.dev, "/visFlow/deleteVisitorData"), {
      params: {
        openId: openId
      }
    });
  },
  upVisUserInfo: function upVisUserInfo(params) {
    //注册用户
    return http.post("".concat(base.dev, "/visFlow/registerVisitorData"), params);
  },
  updatedVisList: function updatedVisList(params) {
    //上传新增访客
    return http.post("".concat(base.dev, "/visFlow/createOrder"), params);
  },
  queryAuditAlready: function queryAuditAlready(params) {
    //获取已经审批的列表
    return http.post("".concat(base.dev, "/visFlow/queryAuditAlready"), params);
  },
  queryWaitAduit: function queryWaitAduit(params) {
    //获取待审批的列表
    return http.post("".concat(base.dev, "/visFlow/queryWaitAduit"), params);
  },
  selectVisitorList: function selectVisitorList(masterSerial) {
    //获取访客列表
    return http.post("".concat(base.dev, "/visFlow/selectVisitorList"), {
      masterSerial: masterSerial
    });
  },
  updateVisitorData: function updateVisitorData(params) {
    //新增或者修改访客信息
    return http.post("".concat(base.dev, "/visFlow/updateVisitorData"), params);
  },
  deleteVisitor: function deleteVisitor(params) {
    //删除随访人
    return http.post("".concat(base.dev, "/visFlow/deleteVisitor"), params);
  },
  visRegisterList: function visRegisterList(params) {
    //所有访问人
    return http.post("".concat(base.dev, "/visApprove/visRegisterList"), params);
  },
  queryVisRegisterInfo: function queryVisRegisterInfo(params) {
    //访客详情
    return http.post("".concat(base.dev, "/visFlow/queryVisRegisterInfo"), params);
  },
  // ----审批的----
  selectPointList: function selectPointList(params) {
    //内部申请--审批模块
    return http.get("".concat(base.dev, "/visApprove/selectPointList"), params);
  },
  auditReview: function auditReview(params) {
    //提交访客审批意见
    return http.post("".concat(base.dev, "/visFlow/auditReview"), params);
  },
  queryVisAuditInfo: function queryVisAuditInfo(params) {
    //全部审批节点
    return http.post("".concat(base.dev, "/visFlow/queryVisAuditInfo"), params);
  },
  endVisits: function endVisits(documentNo) {
    //全部审批节点
    return http.get("".concat(base.dev, "/visApprove/endVisit"), {
      params: {
        documentNo: documentNo
      }
    });
  },
  delayVisitTime: function delayVisitTime(params) {
    //全部审批节点
    return http.post("".concat(base.dev, "/visApprove/delayVisitTime"), params);
  },
  // ----
  selectvisRegister: function selectvisRegister(params) {
    //--推送--公众号
    return http.post("".concat(base.dev, "/visApprove/selectvisRegister"), params);
  }
};
export default futianVis;