/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import router from '../router/index';
import { Toast } from "vant"; // 创建 axios 实例

var service = axios.create({
  timeout: 1000 * 5
});

var tip = function tip(msg) {
  Toast(msg);
};

var toSignIn = function toSignIn() {
  tip('请重新登录');
  var openId = window.vm.$utils.Store.getItem("openId");
  window.vm.$utils.Store.clear();
  window.vm.$utils.Store.setItem("openId", openId);
  router.replace('/');
};

var errorHandler = function errorHandler(status) {
  switch (status) {
    case 401:
      toSignIn();
      break;

    case 403:
      tip('暂无权限');
      break;

    case 404:
      tip('请求资源不存在');
      break;

    case 405:
      tip('请求方式不正确');
      break;
  }
};
/**
 * 请求拦截器
 * 本地有 token 需要带上
 */


service.interceptors.request.use(function (config) {
  // 接口请求控制,本地有 token 带上
  // 后台根据 token 判断用户状态,并返回给我们状态码
  // 在后面的响应拦截器,根据 状态码 做处理
  // const token = 'ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6STFOaUo5LmV5SnBZWFFpT2pFMU56Z3lOelk0TkRnc0luTjFZaUk2SW14dloybHVJaXdpYVhOeklqb2lkMlZrY3lJc0ltVjRjQ0k2TVRVNE1EZzJPRGcwT0N3aWNHRnNaeUk2SW1GbGN5MHlOVFl0WTJKaklpd2lhblJwSWpvaVJFTTJRak5FT0VSRFFVSkZORVJGUWpnM1JrSkRRalV5TVVJME5UVTJNVUlpTENKaGRXUWlPaUpsWkNJc0luQmtZWFJoSWpvaVMxWjNiVE5JYldGbVVXNVBWM2xWU0M5VWRuaEhPVk5DV1hCMWNrTkljV05rUVhZd1ZGWXJaV2RuV0ZvMVJWaDBiWE0xVkRkM01tNU9Va1ZOWXpWVWRDSjkuQXEtMnpIdUlfWXdLVWFlRGdDWU1wTmtONURpSndhVUFhSU5NaEtTWmEtUQ==';
  var token = window.vm.$utils.Store.getItem('token');
  var app_token = window.vm.$utils.Store.get('token');

  if (token) {
    config.headers['Authorization'] = '' + token;
  }

  if (app_token) {
    config.headers['Authorization'] = '' + app_token;
  }

  var companyId = window.vm.$utils.Store.getItem('companyId');

  if (!config.headers['CompanyId'] && companyId) {
    config.headers['CompanyId'] = companyId;
  }

  config.headers['Channel'] = 'app';
  return config;
}, function (error) {
  return Promise.reject(error);
});
/**
 * 响应拦截器
 */

service.interceptors.response.use(function (response) {
  return response.status === 200 ? Promise.resolve(response.data) : Promise.reject(response);
}, function (error) {
  var response = error.response;

  if (response) {
    return errorHandler(response.status);
  } else {
    if (error.message.indexOf("timeout") > -1) {
      // ECONNABORTED
      tip('请求服务超时');
    } else {
      tip('请求服务异常');
    } // 处理断网情况

  } // Promise.reject(error);

});
export default service;