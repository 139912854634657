/* this file is transformed by vux-loader */
/* eslint-disable */
import _typeof from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import Vue from 'vue';
/*
 * 全局事件
 */

var bus = new Vue();
var busQueue = {}; //监听事件

export var on = function on(eventName, eventHandle) {
  if (eventName && typeof eventHandle === 'function') {
    if (busQueue[eventName]) {
      bus.$off(eventName, busQueue[eventName]);
    }

    busQueue[eventName] = eventHandle;
    return bus.$on(eventName, eventHandle);
  }
}; //触发事件

export var emit = function emit(eventName, msg) {
  // eventName 或者 eventName__fix 都会触发
  var busQueueKeys = Object.keys(busQueue);
  busQueueKeys.forEach(function (key) {
    if (eventName === key.split('__')[0]) {
      return bus.$emit(key, msg);
    }
  });
}; // 非生产环境日志

export var log = function log() {// if (process.env.NODE_ENV !== 'production') {
  // }
};
export var warn = function warn() {// if (process.env.NODE_ENV !== 'production') {
  // }
};
export var error = function error() {// if (process.env.NODE_ENV !== 'production') {
  // }
};
/*
 * 深拷贝
 */

export var deepcopy = function deepcopy(source) {
  if (!source) {
    return source;
  }

  var sourceCopy = source instanceof Array ? [] : {};

  for (var item in source) {
    sourceCopy[item] = _typeof(source[item]) === 'object' ? deepcopy(source[item]) : source[item];
  }

  return sourceCopy;
};