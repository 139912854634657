/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * @Author: your name
 * @Date: 2021-03-31 13:34:44
 * @LastEditTime: 2021-03-31 13:46:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \企业标准\src\api\base.js
 */
import Params from "@/api/global";
var BASE = {
  dev: ''
};

if (process.env.NODE_ENV !== 'production') {
  BASE.dev = 'http://10.17.0.12:20079/app';
} else if (process.env.NODE_ENV == 'production' && Params.mode === '1') {
  BASE.dev = './web';
} else if (process.env.NODE_ENV == 'production') {
  BASE.dev = './app';
}

export default BASE;