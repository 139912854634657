/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var DingTalk = {
  getUnionId: function getUnionId(code) {
    return http.get("".concat(base.dev, "/user/getUserId"), {
      params: {
        code: code
      }
    });
  },
  getDtConfig: function getDtConfig(url) {
    return http.get("".concat(base.dev, "/common/getDtConfig"), {
      params: {
        url: url
      }
    });
  },
  getPayInfo: function getPayInfo(fee) {
    return http.get("".concat(base.dev, "/pay/getPayInfo"), {
      params: {
        fee: fee
      }
    });
  },
  redirectPay: function redirectPay(fee) {
    return http.get("".concat(base.dev, "/pay/redirectPay"), {
      params: {
        fee: fee
      }
    });
  },
  payDtOrder: function payDtOrder(params) {
    return http.put("".concat(base.dev, "/account/payDtOrder"), params);
  },
  payDtOrderTemp: function payDtOrderTemp(params) {
    return http.put("".concat(base.dev, "/account/payDtOrderTemp"), params);
  },
  getDtResultDetail: function getDtResultDetail(params) {
    return http.post("".concat(base.dev, "/account/getDtResultDetail"), params);
  }
};
export default DingTalk;