/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Application = {
  getMyApplication: function getMyApplication(params) {
    return http.post("".concat(base.dev, "/myRecord/getMyApplication"), params);
  },
  getMyApproval: function getMyApproval(params) {
    return http.post("".concat(base.dev, "/myRecord/getMyApproval"), params);
  },
  getMyApproved: function getMyApproved(params) {
    return http.post("".concat(base.dev, "/myRecord/getMyApproved"), params);
  },
  getMyCopy: function getMyCopy(params) {
    return http.post("".concat(base.dev, "/myRecord/getMyMessage"), params);
  },
  deletePhoto: function deletePhoto(imgPath) {
    return http.delete("".concat(base.dev, "/management/deletePhoto"), {
      params: {
        imgPath: imgPath
      }
    });
  }
};
export default Application;