/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var MealAppraise = {
  insert: function insert(params) {
    return http.put("".concat(base.dev, "/edMealAppraise/insert"), params);
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/edMealAppraise/updateByPrimaryKey"), params);
  },
  selectListPageByEntity: function selectListPageByEntity(params) {
    return http.post("".concat(base.dev, "/edMealAppraise/selectListPageByEntity"), params);
  },
  selectListByEntity: function selectListByEntity(params) {
    return http.post("".concat(base.dev, "/edMealAppraise/selectListByEntity"), params);
  },
  deleteByPrimaryKey: function deleteByPrimaryKey(bh) {
    return http.delete("".concat(base.dev, "/edMealAppraise/deleteByPrimaryKey"), {
      params: {
        bh: bh
      }
    });
  },
  selectByPrimaryKey: function selectByPrimaryKey(bh) {
    return http.get("".concat(base.dev, "/edMealAppraise/selectByPrimaryKey"), {
      params: {
        bh: bh
      }
    });
  }
};
export default MealAppraise;