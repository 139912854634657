/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Leave = {
  getLeaveDetail: function getLeaveDetail(params) {
    return http.post("".concat(base.dev, "/leave/selectLeaveByXh"), params);
  },
  selectLeave: function selectLeave(params) {
    return http.post("".concat(base.dev, "/leave/selectLeave"), params);
  },
  insertLeave: function insertLeave(params) {
    return http.post("".concat(base.dev, "/leave/insertLeave"), params);
  },
  deleteLeave: function deleteLeave(xh, userSerial, reason) {
    return http.delete("".concat(base.dev, "/leave/deleteLeave"), {
      params: {
        xh: xh,
        userSerial: userSerial,
        reason: reason
      }
    });
  },
  approvalLeave: function approvalLeave(params) {
    return http.post("".concat(base.dev, "/leave/approvalLeave"), params);
  },
  getLeaveLength: function getLeaveLength(params) {
    return http.post("".concat(base.dev, "/leave/getLeaveLength"), params);
  }
};
export default Leave;