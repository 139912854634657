/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Visit = {
  addVisitorOrder: function addVisitorOrder(params) {
    return http.post("".concat(base.dev, "/visRegisterNew/insertOrUpdate"), params, {
      headers: {
        'CompanyId': params.companyId
      }
    });
  },
  addVisitorOrders: function addVisitorOrders(params) {
    //一汽富维定制的
    return http.put("".concat(base.dev, "/fwVisRegisterNew/insertOrUpdate"), params, {
      headers: {
        'CompanyId': params.companyId
      }
    });
  },
  getVisitorInfo: function getVisitorInfo(openId, companyId) {
    return http.get("".concat(base.dev, "/visUserNew/selectByOpenId"), {
      params: {
        openId: openId
      },
      headers: {
        'CompanyId': companyId
      }
    });
  },
  getVistorTimeStting: function getVistorTimeStting() {
    return http.get("".concat(base.dev, "/visRegisterNew/time-setting"), {});
  },
  getReasonList: function getReasonList(params) {
    return http.post("".concat(base.dev, "/visThing/selectListByEntity"), params, {
      headers: {
        'CompanyId': params.companyId
      }
    });
  },
  getPlaceList: function getPlaceList(params) {
    return http.post("".concat(base.dev, "/visRegion/selectListByEntity"), params);
  },
  selectByPrimaryKey: function selectByPrimaryKey(xh) {
    return http.get("".concat(base.dev, "/visRegisterNew/selectByPrimaryKey"), {
      params: {
        xh: xh
      }
    });
  },
  selectByPrimaryKey_fu: function selectByPrimaryKey_fu(xh) {
    //---富维    获取详情
    return http.get("".concat(base.dev, "/fwVisRegisterNew/selectByPrimaryKey"), {
      params: {
        xh: xh
      }
    }); //富维
    // return http.get(`${base.dev}/visRegisterNew/selectByPrimaryKey`, {params: {xh}});
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/visRegisterNew/updateVis"), params);
  },
  updateByPrimaryKey_fu: function updateByPrimaryKey_fu(params) {
    //-------------富维定制   审批接口i
    return http.post("".concat(base.dev, "/fwVisRegisterNew/updateVis"), params);
  },
  selectByCardNo: function selectByCardNo(params) {
    return http.post("".concat(base.dev, "/visRegisterNew/selectByCardNo"), params);
  },
  selectListPageByEntity: function selectListPageByEntity(params) {
    return http.post("".concat(base.dev, "/visRegisterNew/selectListPageByEntity"), params);
  },
  selectListPageByEntity_fu: function selectListPageByEntity_fu(params) {
    return http.post("".concat(base.dev, "/fwVisRegisterNew/selectListPageByEntity"), params);
  },
  visitInfo: function visitInfo(params) {
    return http.post("".concat(base.dev, "/userBase/register"), params);
  },
  getVisitDate: function getVisitDate(id, companyId) {
    return http.get("".concat(base.dev, "/visSetting/selectByPrimaryKey"), {
      params: {
        id: id
      },
      headers: {
        'CompanyId': companyId
      }
    });
  },
  getVisitType: function getVisitType() {
    return http.get("".concat(base.dev, "/visRegisterNew/selectVisDiscernType"));
  },
  selectAllListByEntity: function selectAllListByEntity(params) {
    return http.post("".concat(base.dev, "/visDoorKeeperArea/selectAllListByEntity"), params);
  },
  //判断电话号是否必填项/visRegisterNew/getPhoneConfig
  getPhoneConfig: function getPhoneConfig() {
    return http.get("".concat(base.dev, "/visRegisterNew/getPhoneConfig"));
  }
};
export default Visit;