/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Door = {
  getDoor: function getDoor(params) {
    return http.post("".concat(base.dev, "/mjMobile/selectDoorList"), params);
  },
  updateDoor: function updateDoor(params) {
    return http.post("".concat(base.dev, "/mjMobile/updateGateOrder"), params);
  },
  openDoor: function openDoor(params) {
    return http.post("".concat(base.dev, "/mjMobile/openDoor"), params);
  },
  getMjInfo: function getMjInfo(xh) {
    return http.get("".concat(base.dev, "/mjMobile/getMjInfo"), {
      params: {
        xh: xh
      }
    });
  }
};
export default Door;