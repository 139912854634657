/* this file is transformed by vux-loader */
/* eslint-disable */
import Base from './base';
import Login from './page/login';
import Dine from './page/dine';
import Info from './page/info';
import Sys from './page/sys';
import Spend from './page/spend';
import Attence from './page/attence';
import Door from './page/door';
import Home from './page/home';
import Org from './page/org';
import My from './page/my';
import Message from './page/message';
import Application from './page/application';
import Leave from './page/leave';
import Approver from './page/approver';
import Copyer from './page/copyer';
import Trip from './page/trip';
import Out from './page/out';
import Overtime from './page/overtime';
import ChangeShift from './page/changeshift';
import Repair from './page/repair';
import Meeting from './page/meeting';
import UploadImage from './page/uploadImage';
import Repairs from './page/repairs';
import Dict from './page/dict';
import Visit from './page/visit';
import Meal from './page/meal';
import User from './page/user';
import CompanyInfo from './page/companyInfo';
import DepUser from './page/depUser';
import UserPower from './page/userPower';
import AcDep from './page/acDep';
import DingTalk from './page/dingtalk';
import Agent from './page/agent';
import MealAppraise from './page/mealAppraise';
import Hair from './page/hair';
import Health from './page/health';
import BodyTemp from './page/bodyTemp';
import futianVis from '@/api/page/futianVis';
import Appr from '@/api/page/appr';
import Bus from '@/api/page/bus';
import Baocan from '@/api/page/baocan';
var api = {
  Login: Login,
  Dine: Dine,
  Info: Info,
  Sys: Sys,
  Spend: Spend,
  Attence: Attence,
  Door: Door,
  Home: Home,
  Base: Base,
  Org: Org,
  My: My,
  Message: Message,
  Application: Application,
  Leave: Leave,
  Approver: Approver,
  Copyer: Copyer,
  Trip: Trip,
  Out: Out,
  Overtime: Overtime,
  ChangeShift: ChangeShift,
  Repair: Repair,
  Meeting: Meeting,
  UploadImage: UploadImage,
  Repairs: Repairs,
  Dict: Dict,
  Visit: Visit,
  Meal: Meal,
  User: User,
  CompanyInfo: CompanyInfo,
  DepUser: DepUser,
  UserPower: UserPower,
  AcDep: AcDep,
  DingTalk: DingTalk,
  Agent: Agent,
  MealAppraise: MealAppraise,
  Hair: Hair,
  Health: Health,
  BodyTemp: BodyTemp,
  futianVis: futianVis,
  Appr: Appr,
  Bus: Bus,
  Baocan: Baocan
};
export default {
  install: function install(Vue) {
    Vue.prototype.$api = api;
  },
  $api: api
};