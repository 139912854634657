/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Bus = {
  selectListPageByEntity: function selectListPageByEntity(params) {
    //我的班车路线
    return http.post("".concat(base.dev, "/line/selectListPageByEntity"), params);
  },
  selectAllBusList: function selectAllBusList(params) {
    //查询所有车辆信息
    return http.post("".concat(base.dev, "/line/selectAllBusList"), params);
  },
  selectByPrimaryKey: function selectByPrimaryKey(busBh) {
    //查询班车轨迹
    return http.get("".concat(base.dev, "/bus/selectByPrimaryKey"), {
      params: {
        busBh: busBh
      }
    });
  },
  selectBusListByLine: function selectBusListByLine(params) {
    //查询线路班车所在位置
    return http.post("".concat(base.dev, "/line/selectBusListByLine"), params);
  },
  selectPointListByEntity: function selectPointListByEntity(params) {
    //查询线路站点详情
    return http.post("".concat(base.dev, "/line/selectPointListByEntity"), params);
  },
  selectBusListByBus: function selectBusListByBus(params) {
    //查询车辆信息详情
    return http.post("".concat(base.dev, "/line/selectBusListByBus"), params);
  },
  selectByDevbh: function selectByDevbh(bh) {
    // 根据设备编号快速查询班车轨迹-实时请求班车位置
    return http.get("".concat(base.dev, "/bus/getBusBybh"), {
      params: {
        bh: bh
      }
    });
  },
  selectAllGPSBusList: function selectAllGPSBusList(params) {
    //查询所有gps车辆信息
    return http.post("".concat(base.dev, "/line/selectGpsBusList"), params);
  },
  selectBusByBh: function selectBusByBh(params) {
    //查询车辆最近路段
    return http.post("".concat(base.dev, "/bus/selectBusByBh"), params);
  },
  refreshBusGps: function refreshBusGps(params) {
    //刷新车辆当前位置
    return http.post("".concat(base.dev, "/bus/refreshBusGps"), params);
  },
  getBusRoad: function getBusRoad(params) {
    //获取车辆全部路段
    return http.post("".concat(base.dev, "/bus/getBusRoad"), params);
  }
};
export default Bus;