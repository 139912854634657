/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var DepUser = {
  insert: function insert(params) {
    return http.put("".concat(base.dev, "/depUser/insert"), params);
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/depUser/updateByPrimaryKey"), params);
  },
  selectListPageByEntity: function selectListPageByEntity(params) {
    return http.post("".concat(base.dev, "/depUser/selectListPageByEntity"), params);
  },
  selectListByEntity: function selectListByEntity(params) {
    return http.post("".concat(base.dev, "/depUser/selectListByEntity"), params);
  },
  deleteByPrimaryKey: function deleteByPrimaryKey(userSerial) {
    return http.delete("".concat(base.dev, "/depUser/deleteByPrimaryKey"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  selectByPrimaryKey: function selectByPrimaryKey(userSerial) {
    return http.get("".concat(base.dev, "/depUser/selectByPrimaryKey"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  selectListByKeys: function selectListByKeys(params) {
    return http.post("".concat(base.dev, "/depUser/selectListByKeys"), params);
  },
  deleteBatchByKeys: function deleteBatchByKeys(keys) {
    return http.delete("".concat(base.dev, "/depUser/deleteBatchByKeys"), {
      params: {
        keys: keys
      }
    });
  }
};
export default DepUser;