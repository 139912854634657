/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var UploadImage = {
  insertPhoto: function insertPhoto(params) {
    return http.post("".concat(base.dev, "/management/insertPhoto"), params);
  },
  deletePhoto: function deletePhoto(imgPath) {
    return http.delete("".concat(base.dev, "/management/deletePhoto"), {
      params: {
        imgPath: imgPath
      }
    });
  }
};
export default UploadImage;