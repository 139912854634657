/* this file is transformed by vux-loader */
/* eslint-disable */
var getStoryage02 = function getStoryage02(item) {
  var str = window.sessionStorage.getItem(item);
  return JSON.parse(str);
};

var list = getStoryage02('visList') || [{
  id: Date.now(),
  obj: {}
}];
var state = {
  foodList: [],
  visList: list,
  //被访问者的列表
  visThing: {},
  //来访事由
  onlyReadThing: {},
  //来访事由---只读的详情
  exInfo: {},
  //被访问者--只读
  userVInfo: {},
  //被访问者---填写的
  uploadImgSrc: null,
  //上传图片路径
  isPosition: {
    //定位考勤
    num: null,
    mediaId: null
  },
  outSideItem: {},
  itemMsg: {},
  uploadQualityInfo: {} //头像质量检测

};
export default state;