/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
export default {
  name: 'app',
  data: function data() {
    return {};
  },
  mounted: function mounted() {// document.querySelector('#app').style.height=document.querySelector('body').clientHeight+'px';
  }
};
document.addEventListener('touchend', function (e) {
  if (e.target.tagName != 'INPUT') {
    inputBlur();
  }
}, true);
inputBlur();

function inputBlur() {
  document.querySelectorAll('input').forEach(function (v) {
    v.blur();
  });
}