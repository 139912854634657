/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Baocan = {
  getRqMeals: function getRqMeals(params) {
    //新增报餐
    return http.post("".concat(base.dev, "/reportMeal/getMeals"), params);
  },
  insertOrder: function insertOrder(params) {
    //报餐下单
    return http.post("".concat(base.dev, "/reportMeal/insertOrder"), params);
  },
  cancelMeal: function cancelMeal(params) {
    //撤销报餐
    return http.post("".concat(base.dev, "/reportMeal/cancelMeal"), params);
  },
  updateMeal: function updateMeal(params) {
    //撤销报餐
    return http.post("".concat(base.dev, "/reportMeal/updateMeal"), params);
  }
};
export default Baocan;