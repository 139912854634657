/* this file is transformed by vux-loader */
/* eslint-disable */
var getStoryage = function getStoryage(item) {
  var str = window.vm.$utils.Store.getLocalItem(item);
  return JSON.parse(str);
};

var getters = {
  getterFoodList: function getterFoodList(state) {
    return state.foodList != '[]' ? state.foodList : getStoryage('foodList');
  },
  getVisList: function getVisList(state) {
    return state.visList;
  },
  getThing: function getThing(state) {
    return state.visThing;
  },
  visThing: function visThing(state) {
    return state.onlyReadThing;
  },
  getExamInfo: function getExamInfo(state) {
    return state.exInfo;
  },
  getUploadImg: function getUploadImg(state) {
    return state.uploadImgSrc;
  },
  getPositionNum: function getPositionNum(state) {
    return state.isPosition;
  },
  getOutSide: function getOutSide(state) {
    return state.outSideItem;
  },
  getItemMsg: function getItemMsg(state) {
    return state.itemMsg;
  },
  getQuliatyInfo: function getQuliatyInfo(state) {
    return state.uploadQualityInfo;
  }
};
export default getters;