/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
});