/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import base from '../base';
import http from '../http';
import configJson from '@/assets/json/config.json';
var Sys = {
  getSystemDate: function getSystemDate(mode) {
    if (mode === '1') {
      return http.get("".concat(base.dev, "/wx/getSystemDate"));
    } else {
      // mode == 0
      return http.get("".concat(base.dev, "/system/getSystemDate"));
    }
  },
  getSystemParams: function getSystemParams(tenancyId, paramKey) {
    return http.get("".concat(base.dev, "/sysParam/selectByPrimaryKey"), {
      params: {
        tenancyId: tenancyId,
        paramKey: paramKey
      }
    });
  },
  getWxImageUrl: function getWxImageUrl(mediaId, mode) {
    if (mode === '1') {
      return http.get("".concat(base.dev, "/wx/getWxImageUrl"), {
        params: {
          mediaId: mediaId
        }
      });
    } else {
      return http.get("".concat(base.dev, "/system/getWxImageUrl"), {
        params: {
          mediaId: mediaId
        }
      });
    }
  },
  getSysSetting: function getSysSetting() {
    return Promise.resolve(configJson);
  },
  getConfig: function getConfig(url, mode) {
    if (mode === '1') {
      return http.get("".concat(base.dev, "/wx/getConfig"), {
        params: {
          url: url
        }
      });
    } else {
      return http.get("".concat(base.dev, "/system/getConfig"), {
        params: {
          url: url
        }
      });
    }
  },
  //图片质量/userBase/faceQuality
  faceQuality: function faceQuality(params) {
    return http.post("".concat(base.dev, "/userBase/faceQuality"), params);
  },
  //人脸1：1验证/userBase/checkUserFace
  checkUserFace: function checkUserFace(params) {
    return http.post("".concat(base.dev, "/userBase/checkUserFace"), params);
  }
};
export default Sys;