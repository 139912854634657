/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Trip = {
  getTripDetail: function getTripDetail(params) {
    return http.post("".concat(base.dev, "/businessTravel/selectUserBusiness"), params);
  },
  insertTrip: function insertTrip(params) {
    return http.post("".concat(base.dev, "/businessTravel/insertBusinessTravel"), params);
  },
  deleteTrip: function deleteTrip(xh, userSerial, reason) {
    return http.delete("".concat(base.dev, "/businessTravel/deleteBusiness"), {
      params: {
        xh: xh,
        userSerial: userSerial,
        reason: reason
      }
    });
  },
  approvalTrip: function approvalTrip(params) {
    return http.post("".concat(base.dev, "/businessTravel/approvalBusiness"), params);
  }
};
export default Trip;