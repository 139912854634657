/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Copyer = {
  selectCopyUser: function selectCopyUser(params) {
    return http.post("".concat(base.dev, "/management/selectCopyUser"), params);
  },
  insertCopyUser: function insertCopyUser(params) {
    return http.post("".concat(base.dev, "/management/insertCopyUser"), params);
  },
  deleteCopyUser: function deleteCopyUser(userSerial, copyType) {
    return http.delete("".concat(base.dev, "/management/deleteCopyUser"), {
      params: {
        userSerial: userSerial,
        copyType: copyType
      }
    });
  }
};
export default Copyer;