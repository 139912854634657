/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import Utils from '../utils';
import API from '../api';
Vue.use(VueRouter);
var router = new VueRouter({
  routes: [{
    path: '/Login',
    name: 'Login',
    component: function component() {
      return import('@/views/Login/Login.vue');
    },
    meta: {
      title: '登录'
    }
  }, {
    path: '/PlaceSelect',
    name: 'PlaceSelect',
    component: function component() {
      return import('@/views/OrderMeals/Choose');
    },
    meta: {
      title: '选择餐厅',
      cancelFlag: '1'
    }
  }, {
    path: '/',
    name: 'Home',
    component: function component() {
      return import('@/views/Home/Home');
    },
    children: [{
      path: '/',
      name: 'Index',
      component: function component() {
        return import('@/views/Index/Index');
      },
      meta: {
        title: '主页'
      }
    }, {
      path: '/My',
      name: 'My',
      component: function component() {
        return import('@/views/My/My');
      },
      meta: {
        title: '我'
      }
    }]
  }, {
    path: '/MyQrcode',
    name: 'MyQrcode',
    component: function component() {
      return import('@/views/Index/MyQrcode');
    },
    meta: {
      title: '二维码'
    }
  }, {
    path: '/Account',
    name: 'Account',
    component: function component() {
      return import('@/views/Index/Account');
    },
    meta: {
      title: '账户'
    }
  }, {
    path: '/refund',
    name: 'refund',
    component: function component() {
      return import('@/views/Index/refund');
    },
    meta: {
      title: '账户退款'
    }
  }, {
    path: '/Recharge',
    name: 'Recharge',
    component: function component() {
      return import('@/views/Index/Recharge');
    },
    meta: {
      title: '充值'
    }
  }, {
    path: '/MyApply',
    name: 'MyApply',
    component: function component() {
      return import('@/views/Application/MyApply');
    },
    meta: {
      title: '我的申请'
    }
  }, {
    path: '/LeaveDetail',
    name: 'LeaveDetail',
    component: function component() {
      return import('@/views/Application/LeaveDetail');
    },
    meta: {
      title: '请假详情'
    }
  }, {
    path: '/GoOutDetail',
    name: 'GoOutDetail',
    component: function component() {
      return import('@/views/Application/GoOutDetail');
    },
    meta: {
      title: '公出详情'
    }
  }, {
    path: '/OverTimeDetail',
    name: 'OverTimeDetail',
    component: function component() {
      return import('@/views/Application/OverTimeDetail');
    },
    meta: {
      title: '加班详情'
    }
  }, {
    path: '/ChangeShiftDetail',
    name: 'ChangeShiftDetail',
    component: function component() {
      return import('@/views/Application/ChangeShiftDetail');
    },
    meta: {
      title: '调班详情'
    }
  }, {
    path: '/RepairAttendanceDetail',
    name: 'RepairAttendanceDetail',
    component: function component() {
      return import('@/views/Application/RepairAttendanceDetail');
    },
    meta: {
      title: '补出勤详情'
    }
  }, {
    path: '/ContractDetail',
    name: 'ContractDetail',
    component: function component() {
      return import('@/views/Application/ContractDetail');
    },
    meta: {
      title: '合同详情'
    }
  }, {
    path: '/Overtime',
    name: 'Overtime',
    component: function component() {
      return import('@/views/Application/Overtime');
    },
    meta: {
      title: '加班申请'
    }
  }, {
    path: '/ChangeShift',
    name: 'ChangeShift',
    component: function component() {
      return import('@/views/Application/ChangeShift');
    },
    meta: {
      title: '调班申请'
    }
  }, {
    path: '/ChangePWD',
    name: 'ChangePWD',
    component: function component() {
      return import('@/views/My/ChangePWD');
    },
    meta: {
      title: '修改密码'
    }
  }, {
    path: '/LossSolution',
    name: 'LossSolution',
    component: function component() {
      return import('@/views/My/LossSolution');
    },
    meta: {
      title: '卡片挂失'
    }
  }, {
    path: '/OrderMeeting',
    name: 'OrderMeeting',
    component: function component() {
      return import('@/views/Meeting/OrderMeeting');
    },
    meta: {
      title: '会议预约'
    }
  }, {
    path: '/MeetingRoomDetail',
    name: 'MeetingRoomDetail',
    component: function component() {
      return import('@/views/Meeting/MeetingRoomDetail');
    },
    meta: {
      title: '会议室预约详情'
    }
  }, {
    path: '/MakeOrder',
    name: 'MakeOrder',
    component: function component() {
      return import('@/views/Meeting/MakeOrder');
    },
    meta: {
      title: '预约会议'
    }
  }, {
    path: '/MyMeetingOrder',
    name: 'MyMeetingOrder',
    component: function component() {
      return import('@/views/Meeting/MyMeetingOrder');
    },
    meta: {
      title: '我的会议预约'
    }
  }, {
    path: '/MeetingDetail',
    name: 'MeetingDetail',
    component: function component() {
      return import('@/views/Meeting/MeetingAppoint');
    },
    meta: {
      title: '会议预约详情'
    }
  }, {
    path: '/MyApplyTrip',
    name: 'MyApplyTrip',
    component: function component() {
      return import('@/views/BusinessTrip/MyApplyTrip');
    },
    meta: {
      title: '出差详情'
    }
  }, {
    path: '/FaceTake',
    name: 'FaceTake',
    component: function component() {
      return import('@/views/Face/FaceTake');
    },
    meta: {
      title: '人脸采集'
    }
  }, {
    path: '/FaceImg',
    name: '开始采集',
    component: function component() {
      return import('@/views/Face/face_begin');
    },
    meta: {
      title: '人脸采集'
    }
  }, {
    path: '/RichScan',
    name: 'RichScan',
    component: function component() {
      return import('@/views/Index/RichScan');
    },
    meta: {
      title: '扫一扫'
    }
  }, {
    path: '/SpendDetail',
    name: 'SpendDetail',
    component: function component() {
      return import('@/views/Consumption/SpendDetail');
    },
    meta: {
      title: '消费记录'
    }
  }, {
    path: '/SpendDetailDetail',
    name: 'SpendDetailDetail',
    component: function component() {
      return import('@/views/Consumption/SpendDetailDetail');
    },
    meta: {
      title: '账户详情'
    }
  }, {
    path: '/OutSide',
    name: 'OutSide',
    component: function component() {
      return import('@/views/Attence/OutSide');
    },
    meta: {
      title: '外勤'
    }
  }, {
    path: '/BdMap',
    name: 'BdMap',
    component: function component() {
      return import('@/views/Attence/BdMap');
    },
    meta: {
      title: '地图定位'
    }
  }, {
    path: '/StartWork',
    name: 'StartWork',
    component: function component() {
      return import('@/views/Attence/StartWork');
    },
    meta: {
      title: '定位考勤'
    }
  }, {
    path: '/takePhoto',
    name: '打开拍照',
    component: function component() {
      return import('@/views/Attence/commonTakePhoto');
    },
    meta: {
      title: '打卡拍照'
    }
  }, {
    path: '/Attendances',
    name: 'Attendances',
    component: function component() {
      return import('@/views/Attence/Attendances');
    },
    meta: {
      title: '考勤汇总'
    }
  }, {
    path: '/AttendDetail',
    name: 'AttendDetail',
    component: function component() {
      return import('@/views/Attence/AttendDetail');
    },
    meta: {
      title: '考勤记录'
    }
  }, {
    path: '/DoorControl',
    name: 'DoorControl',
    component: function component() {
      return import('@/views/DoorControl/DoorControl');
    },
    meta: {
      title: '门禁开门'
    }
  }, {
    path: '/PayResult',
    name: 'PayResult',
    component: function component() {
      return import('@/views/Index/PayResult');
    },
    meta: {
      title: '充值信息'
    }
  }, {
    path: '/InvitationList',
    name: 'InvitationList',
    component: function component() {
      return import('@/views/Visitor/InvitationList');
    },
    meta: {
      title: '访客邀请记录'
    }
  }, {
    path: '/VisitorDetail',
    name: 'VisitorDetail',
    component: function component() {
      return import('@/views/Visitor/VisitorDetail');
    },
    meta: {
      title: '访客记录详情'
    }
  }, {
    path: '/InvitationResult',
    name: 'InvitationResult',
    component: function component() {
      return import('@/views/Visitor/InvitationResult');
    },
    meta: {
      title: '访客邀请卡'
    }
  }, {
    path: '/CompleteVisitorInfo',
    //H5的页面
    name: 'CompleteVisitorInfo',
    component: function component() {
      return import('@/views/Visitor/CompleteVisitorInfo');
    },
    meta: {
      title: '邀请访客'
    }
  }, {
    path: '/VisitorRegister',
    name: 'VisitorRegister',
    component: function component() {
      return import('@/views/Visitor/VisitorRegister');
    },
    meta: {
      title: '访客登记'
    }
  }, {
    path: '/RegisterResult',
    name: 'RegisterResult',
    component: function component() {
      return import('@/views/Visitor/RegisterResult');
    },
    meta: {
      title: '申请结果'
    }
  }, {
    path: '/VisitPermit',
    name: 'VisitPermit',
    component: function component() {
      return import('@/views/Visitor/VisitPermit');
    },
    meta: {
      title: '访客通行证'
    }
  }, {
    path: '/RepairInfo',
    name: 'RepairInfo',
    component: function component() {
      return import('@/views/Repair/RepairInfo');
    }
  }, {
    path: '/RepairList',
    name: 'RepairList',
    component: function component() {
      return import('@/views/Repair/RepairList');
    }
  }, {
    path: '/RepairDetail',
    name: 'RepairDetail',
    component: function component() {
      return import('@/views/Repair/RepairDetail');
    }
  }, {
    path: '/RepairAppList',
    name: 'RepairAppList',
    component: function component() {
      return import('@/views/Repair/RepairAppList');
    }
  }, {
    path: '/RepairAppInfo',
    name: 'RepairAppInfo',
    component: function component() {
      return import('@/views/Repair/RepairAppInfo');
    }
  }, {
    path: '/AllPrograms',
    name: 'AllPrograms',
    component: function component() {
      return import('@/views/Index/AllPrograms');
    },
    meta: {
      title: '编辑应用'
    }
  }, {
    path: '/MealOrdering',
    name: 'MealOrdering',
    component: function component() {
      return import('@/views/OrderMeals/MealOrdering');
    },
    meta: {
      title: '预约订餐'
    }
  }, {
    path: '/OrderPay',
    name: 'OrderPay',
    component: function component() {
      return import('@/views/OrderMeals/OrderPay');
    },
    meta: {
      title: '订单结算'
    }
  }, {
    path: '/OrderPayResult',
    name: 'OrderPayResult',
    component: function component() {
      return import('@/views/OrderMeals/OrderPayResult');
    },
    meta: {
      title: '订单详情'
    }
  }, {
    path: '/OrderRecord',
    name: 'OrderRecord',
    component: function component() {
      return import('@/views/OrderMeals/OrderRecord');
    },
    meta: {
      title: '订餐记录'
    }
  }, {
    path: '/OrderShare',
    name: 'OrderShare',
    component: function component() {
      return import('@/views/OrderMeals/OrderShare');
    },
    meta: {
      title: '共享订单'
    }
  }, {
    path: '/OrderShareDetail',
    name: 'OrderShareDetail',
    component: function component() {
      return import('@/views/OrderMeals/OrderShareDetail');
    },
    meta: {
      title: '订单详情'
    }
  }, {
    path: '/OutsideList',
    name: 'OutsideList',
    component: function component() {
      return import('@/views/Attence/OutsideList');
    },
    meta: {
      title: '外勤记录'
    }
  }, {
    path: '/OutsideForm',
    name: 'OutsideForm',
    component: function component() {
      return import('@/views/Attence/OutsideForm');
    },
    meta: {
      title: '外勤详情'
    }
  }, {
    path: '/AgentList',
    name: 'AgentList',
    component: function component() {
      return import('@/views/Agent/AgentList');
    },
    meta: {
      title: '报备清单'
    }
  }, {
    path: '/AgentForm',
    name: 'AgentForm',
    component: function component() {
      return import('@/views/Agent/AgentForm');
    },
    meta: {
      title: '报备信息'
    }
  }, {
    path: '/MealAppraiseList',
    name: 'MealAppraiseList',
    component: function component() {
      return import('@/views/MealAppraise/MealAppraiseList');
    },
    meta: {
      title: '评价清单'
    }
  }, {
    path: '/MealAppraiseForm',
    name: 'MealAppraiseForm',
    component: function component() {
      return import('@/views/MealAppraise/MealAppraiseForm');
    },
    meta: {
      title: '就餐评价'
    }
  }, {
    path: '/Attendance',
    name: 'Attendance',
    component: function component() {
      return import('@/views/Sectoral/Attendance');
    },
    meta: {
      title: '考勤统计'
    }
  }, {
    path: '/AttendanceIndex',
    name: 'AttendanceIndex',
    component: function component() {
      return import('@/views/Attendance/Index');
    },
    meta: {
      title: '考勤汇总'
    }
  }, {
    path: '/AttendanceDetail',
    name: 'AttendanceDetail',
    component: function component() {
      return import('@/views/Attendance/Detail');
    },
    meta: {
      title: '考勤汇总'
    }
  }, {
    path: '/Staff',
    name: 'Staff',
    component: function component() {
      return import('@/views/Scheduling/Staff');
    },
    meta: {
      title: '个人排班详情'
    }
  }, {
    path: '/BodyTemp',
    name: 'BodyTemp',
    component: function component() {
      return import('@/views/BodyTemp/Index');
    },
    meta: {
      title: '体温监测',
      keepAlive: true
    }
  }, {
    path: '/TempDetail',
    name: 'TempDetail',
    component: function component() {
      return import('@/views/BodyTemp/TempDetail');
    },
    meta: {
      title: '体温监测'
    }
  }, {
    path: '/OtderAppraise',
    name: 'OtderAppraise',
    component: function component() {
      return import('@/views/OrderMeals/OtderAppraise');
    },
    meta: {
      title: '订餐评价'
    }
  }, {
    path: '/foodCode',
    name: 'foodCode',
    component: function component() {
      return import('@/views/Meal/foodCode');
    },
    meta: {
      title: '取餐信息' //取餐码

    }
  }, {
    path: '/multistageApproval',
    name: 'multistageApproval',
    component: function component() {
      return import('@/views/Approval/multistageApproval');
    },
    meta: {
      title: '多级审批' //多级审批主页

    }
  }, {
    path: '/leaveDetails',
    name: 'leaveDetails',
    component: function component() {
      return import('@/views/Approval/leaveDetails');
    },
    meta: {
      title: '请假申请'
    }
  }, {
    path: '/evectionDetails',
    name: 'evectionDetails',
    component: function component() {
      return import('@/views/Approval/evectionDetails');
    },
    meta: {
      title: '出差申请'
    }
  }, {
    path: '/businessDetails',
    name: 'businessDetails',
    component: function component() {
      return import('@/views/Approval/businessDetails');
    },
    meta: {
      title: '公出申请'
    }
  }, {
    path: '/overtimeDetails',
    name: 'overtimeDetails',
    component: function component() {
      return import('@/views/Approval/overtimeDetails');
    },
    meta: {
      title: '加班申请'
    }
  }, {
    path: '/changeDetails',
    name: 'changeDetails',
    component: function component() {
      return import('@/views/Approval/changeDetails');
    },
    meta: {
      title: '调班申请'
    }
  }, {
    path: '/repairDetails',
    name: 'repairDetails',
    component: function component() {
      return import('@/views/Approval/repairDetails');
    },
    meta: {
      title: '补出勤申请'
    }
  }, {
    path: '/callDetails',
    name: 'callDetails',
    component: function component() {
      return import('@/views/Approval/callDetails');
    },
    meta: {
      title: '销假申请'
    }
  }, {
    path: '/callInfo',
    name: 'callInfo',
    component: function component() {
      return import('@/views/Approval/callInfo');
    },
    meta: {
      title: '销假详情'
    }
  }, {
    path: '/applyDetails',
    name: 'applyDetails',
    component: function component() {
      return import('@/views/Approval/applyDetails');
    },
    meta: {
      title: '我申请的'
    }
  }, {
    path: '/MyMeetingApply',
    name: '我申请的',
    component: function component() {
      return import('@/views/Meeting/MeetingMyApply');
    },
    meta: {
      title: '我申请的'
    }
  }, {
    path: '/MeetingApproval',
    name: '我审批的',
    component: function component() {
      return import('@/views/Meeting/MeetingApproval');
    },
    meta: {
      title: '我审批的'
    }
  }, {
    path: '/approvalDetails',
    name: 'approvalDetails',
    component: function component() {
      return import('@/views/Approval/approvalDetails');
    },
    meta: {
      title: '我审批的'
    }
  }, {
    path: '/copyDetails',
    name: 'copyDetails',
    component: function component() {
      return import('@/views/Approval/copyDetails');
    },
    meta: {
      title: '抄送我的'
    }
  }, {
    path: '/leaveInfo',
    name: 'leaveInfo',
    component: function component() {
      return import('@/views/Approval/leaveInfo');
    },
    meta: {
      title: '请假详情'
    }
  }, {
    path: '/businessInfo',
    name: 'businessInfo',
    component: function component() {
      return import('@/views/Approval/businessInfo');
    },
    meta: {
      title: '公出详情'
    }
  }, {
    path: '/overtimeInfo',
    name: 'overtimeInfo',
    component: function component() {
      return import('@/views/Approval/overtimeInfo');
    },
    meta: {
      title: '加班详情'
    }
  }, {
    path: '/changeInfo',
    name: 'changeInfo',
    component: function component() {
      return import('@/views/Approval/changeInfo');
    },
    meta: {
      title: '调班详情'
    }
  }, {
    path: '/repairInfos',
    name: 'repairInfos',
    component: function component() {
      return import('@/views/Approval/repairInfos');
    },
    meta: {
      title: '补出勤详情'
    }
  }, {
    path: '/evectionInfo',
    name: 'evectionInfo',
    component: function component() {
      return import('@/views/Approval/evectionInfo');
    },
    meta: {
      title: '出差详情'
    }
  }, {
    path: '/legWork',
    name: 'legWork',
    component: function component() {
      return import('@/views/Approval/legWork');
    },
    meta: {
      title: '外勤'
    }
  }, {
    path: '/legWorkInfo',
    name: 'legWorkInfo',
    component: function component() {
      return import('@/views/Approval/legWorkInfo');
    },
    meta: {
      title: '外勤详情'
    }
  }, {
    path: '/MyBus',
    name: 'MyBus',
    component: function component() {
      return import('@/views/Bus/MyBus');
    },
    meta: {
      title: '我的班车路线'
    }
  }, {
    path: '/BusInfo',
    name: 'BusInfo',
    component: function component() {
      return import('@/views/Bus/BusInfo');
    },
    meta: {
      title: '站点详情'
    }
  }, {
    path: '/BusLocation',
    name: 'BusLocation',
    component: function component() {
      return import('@/views/Bus/BusLocation');
    },
    meta: {
      title: '班车轨迹'
    }
  }, {
    path: '/DateMealList',
    name: 'DateMealList',
    component: function component() {
      return import('@/views/MakeMeal/DateMeal');
    },
    meta: {
      // 预约报餐 操作页面
      title: '预约报餐'
    }
  }, {
    path: '/reportMeal',
    name: 'reportMeal',
    component: function component() {
      return import('@/views/OrderMeals/ReportMeal');
    },
    meta: {
      // 预约报餐 餐厅选择
      title: '预约报餐'
    }
  }, {
    path: '/InductionInfo',
    name: 'InductionInfo',
    component: function component() {
      return import('@/views/FileManagement/InductionInfo');
    },
    meta: {
      title: '入职档案'
    }
  }, {
    path: '/ExamineList',
    name: 'ExamineList',
    component: function component() {
      return import('@/views/FileManagement/ExamineList');
    },
    meta: {
      title: '待审核档案'
    }
  }, {
    path: '/InductionDetails',
    name: 'InductionDetails',
    component: function component() {
      return import('@/views/FileManagement/InductionDetails');
    },
    meta: {
      title: '档案详情'
    }
  }, {
    path: '/FileDetail',
    name: 'FileDetail',
    component: function component() {
      return import('@/views/FileManagement/FileDetail');
    },
    meta: {
      title: '档案详情'
    }
  }, {
    path: '/fileList',
    name: 'fileList',
    component: function component() {
      return import('@/views/FileManagement/fileList');
    },
    meta: {
      title: '入职档案'
    }
  }, {
    path: '/organizationList',
    name: 'organizationList',
    component: function component() {
      return import('@/views/FileManagement/organizationList');
    },
    meta: {
      title: '组织架构'
    }
  }, {
    path: '/BusAllList',
    name: 'BusAllList',
    component: function component() {
      return import('@/views/Bus/BusAllList');
    },
    meta: {
      title: '全部班车信息'
    }
  }, {
    path: '/MessageList',
    name: 'MessageList',
    component: function component() {
      return import('@/views/Index/MessageList');
    },
    meta: {
      title: '通知列表'
    }
  }, {
    path: '/Message',
    name: 'Message',
    component: function component() {
      return import('@/views/Index/Message');
    },
    meta: {
      title: '通知详情'
    }
  }]
});
var nocheck = ['VisitorRegister', 'RegisterResult', 'CompleteVisitorInfo', 'MeetingApproval', 'MyMeetingApply', 'VisitPermit', 'InvalidCode', 'CompanyInfoPage', 'FutianVisiRegister', 'visitorExamInfo', 'Vismanage', 'examInfo2', 'vistorHome', 'InformationCollection', 'PlaceSelect', 'getAuthorization', 'OrderRecord', 'MealOrdering', 'OrderPay', 'OrderPayResult', 'OrderShare', 'OrderShareDetail', 'OtderAppraise', 'foodCode']; // 全局路由守卫

router.beforeEach(function (to, from, next) {
  var state = decodeURI(Utils.Tools.getQueryString('state'));

  if (state !== null && state !== '' && to.params.state === undefined) {
    to.params['state'] = state;
    to.params['notification'] = 1; //设定一个状态值，默认为1，
  }

  if (nocheck.indexOf(to.name) > -1) {
    next();
  } else {
    var openId = Utils.Store.getItem('openId');
    var init = Utils.Store.getItem('init');
    var token = Utils.Store.getItem('token');
    var unionId = to.query.unionId;

    if (unionId !== undefined && init === null) {
      getOpenIdByUnionId(unionId, next);
    } else {
      Utils.Tools.getEnv(function (res) {
        if (res === 0 || res === 1) {
          var wxcode = Utils.Tools.getQueryString('code'); // 已登录状态；当路由到Login时，跳转至Index

          if (openId === null && init === null) {
            if (res === 0) {
              setOpenId(wxcode, next);
            } else {
              getOpenId(wxcode, to, next, state);
            }
          } else {
            if (token === null && to.name !== 'Login') {
              router.replace({
                // name: 'Login'
                name: 'Login'
              });
            } else {
              next();
            }
          }
        } else {
          if (openId === null && init === null) {
            dtLogin(to, next, state);
          } else {
            // if (token === null && to.name !== 'Login') {
            if (token === null && to.name !== 'Login') {
              router.replace({
                name: 'Login'
              });
            } else {
              next();
            }
          }
        }
      });
    }
  }
});

function onWeixinBridgeReady() {
  window.WeixinJSBridge.call('hideOptionMenu');
}

router.afterEach(function (to) {
  Utils.Tools.getEnv(function (res) {
    if (res === 1) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onWeixinBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onWeixinBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onWeixinBridgeReady);
        }
      } else {
        onWeixinBridgeReady();
      }
    }
  }); // document.body && (document.body.scrollTop = document.body.scrollTop);

  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '智慧企业平台';
  }
});

function dtLogin(to, next, state) {
  API.$api.DingTalk.getDtConfig().then(function (response) {
    var code = response.code,
        data = response.data;

    if (+code !== Vue.prototype.$code.success) {
      Utils.Tools.toastShow('获取企业信息异常');
      next(false);
    } else {
      Utils.DingTalk.getCode(data.corpId, function (info) {
        API.$api.DingTalk.getUnionId(info.code).then(function (response) {
          var code = response.code,
              data = response.data;

          if (+code !== Vue.prototype.$code.success) {
            Utils.Tools.toastShow('获取用户信息异常');
            next(false);
          } else {
            getUserInfoByOpenId(data.userid, to, next, state);
          }
        }).catch(function () {
          Utils.Tools.toastShow('后台服务异常');
        });
      });
    }
  }).catch(function () {
    Utils.Tools.toastShow('后台服务异常');
  });
}

function getOpenIdByUnionId(unionId, next) {
  API.$api.Login.getOpenIdByUnionId(unionId, 10000).then(function (response) {
    var code = response.code,
        msg = response.msg,
        data = response.data;

    if (+code !== Vue.prototype.$code.success) {
      if (+code === 601) {
        window.location = data.user1;
      } else if (+code === 605) {
        // 微信小程序返回
        window.vm.$wechat.miniProgram.navigateBack({
          delta: 1
        });
        window.vm.$wechat.miniProgram.postMessage({
          code: '601',
          data: msg
        });
      }

      Utils.Tools.toastShow('后台服务异常');
      next(false);
    } else {
      Utils.Store.setItem('init', 1);
      Utils.Custom.setUserInfo(data);
      next();
    }
  }).catch(function () {
    window.vm.$wechat.miniProgram.navigateBack({
      delta: 1
    });
    window.vm.$wechat.miniProgram.postMessage({
      code: '601',
      data: '后台服务异常'
    });
  });
}

function setOpenId(wxcode, next) {
  API.$api.Login.setOpenId(wxcode).then(function (response) {
    var code = response.code,
        msg = response.msg,
        data = response.data;
    Utils.Store.setItem('init', 1);

    if (+code !== Vue.prototype.$code.success) {
      // 微信小程序返回
      // Utils.Tools.toastShow(msg);
      window.vm.$wechat.miniProgram.navigateBack({
        delta: 1
      });
      window.vm.$wechat.miniProgram.postMessage({
        code: '601',
        data: msg
      });
    } else {
      Utils.Custom.setUserInfo(data);
      next();
    }
  }).catch(function () {
    Utils.Tools.getEnv(function (res) {
      if (res === 0) {
        window.vm.$wechat.miniProgram.navigateBack({
          delta: 1
        });
        window.vm.$wechat.miniProgram.postMessage({
          code: '601',
          data: '后台服务异常'
        });
      } else {
        Utils.Tools.toastShow('后台服务异常');
      }
    });
  });
}

function getOpenId(wxcode, to, next, state) {
  API.$api.Login.getOpenId(wxcode, Vue.prototype.$params.mode).then(function (response) {
    //正式
    var code = response.code,
        data = response.data;
    var _data = data;
    Utils.Store.setItem('init', 1);

    if (_data.companyId) {
      Utils.Store.setItem('companyId', _data.companyId);
    }

    if (+code === Vue.prototype.$code.success) {
      getUserInfoByOpenId(_data.openId, to, next, state);
    } else {
      Utils.Tools.toastShow('获取微信OpenId失败');
      Utils.Store.removeItem('init');
    }

    Utils.Store.setItem('firstLogin', null);
  }).catch(function () {
    Utils.Tools.getEnv(function (res) {
      if (res === 0) {
        window.vm.$wechat.miniProgram.navigateBack({
          delta: 1
        });
        window.vm.$wechat.miniProgram.postMessage({
          code: '601',
          data: '后台服务异常'
        });
      } else {
        Utils.Tools.toastShow('后台服务异常');
      }
    });
  });
}

function getUserInfoByOpenId(openId, to, next, state) {
  API.$api.Login.getUserInfoByOpenId(openId).then(function (response) {
    var code = response.code,
        data = response.data;

    if (+code !== Vue.prototype.$code.success) {
      Utils.Store.setItem('firstLogin', 1);
      Utils.Store.setItem('openId', openId);

      if (to.name !== 'Login') {
        next({
          name: 'Login',
          params: {
            page: to.name,
            state: state
          },
          replace: true
        });
      } else {
        next();
      }
    } else {
      Utils.Custom.setUserInfo(data);
      next();
    }
  }).catch(function () {});
}

export default router;