/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Out = {
  getOutDetail: function getOutDetail(params) {
    return http.post("".concat(base.dev, "/out/selectOutByXh"), params);
  },
  insertOut: function insertOut(params) {
    return http.post("".concat(base.dev, "/out/insertOut"), params);
  },
  deleteOut: function deleteOut(xh, userSerial, reason) {
    return http.delete("".concat(base.dev, "/out/deleteOut"), {
      params: {
        xh: xh,
        userSerial: userSerial,
        reason: reason
      }
    });
  },
  approvalOut: function approvalOut(params) {
    return http.post("".concat(base.dev, "/out/approvalOut"), params);
  },
  // /attence/addWq
  addWq: function addWq(params) {
    return http.post("".concat(base.dev, "/attence/addWq"), params);
  }
};
export default Out;