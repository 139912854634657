/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Message = {
  getMessageDetail: function getMessageDetail(params) {
    if (window.vm.$params.message === '1') {
      return http.post("".concat(base.dev, "/nEWS/selectByPrimaryKey"), params);
    } else if (window.vm.$params.message === '2') {
      return http.post("".concat(base.dev, "/noticeController/selectByBh"), params);
    } else {
      return http.post("".concat(base.dev, "/nEWS/selectByPrimaryKeyNew"), params);
    }
  },
  getMessageData: function getMessageData(params) {
    if (window.vm.$params.message === '1') {
      return http.post("".concat(base.dev, "/nEWS/selectListPageByEntity"), params);
    } else if (window.vm.$params.message === '2') {
      return http.post("".concat(base.dev, "/noticeController/selectByUser"), params);
    } else {
      return http.post("".concat(base.dev, "/nEWS/selectListPageByEntityNew"), params);
    }
  }
};
export default Message;