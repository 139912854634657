/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.replace.js";
import moment from 'moment'; // 里面的字符可以根据自己的需要进行调整

moment.locale('zh-cn', {
  months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
  monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
  weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY-MM-DD',
    LL: 'YYYY年MM月DD日',
    LLL: 'YYYY年MM月DD日Ah点mm分',
    LLLL: 'YYYY年MM月DD日ddddAh点mm分',
    l: 'YYYY-M-D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日dddd HH:mm',
    DT: 'YYYY-MM-DD HH:mm'
  },
  meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
  meridiemHour: function meridiemHour(hour, meridiem) {
    if (hour === 12) {
      hour = 0;
    }

    if (meridiem === '凌晨' || meridiem === '早上' || meridiem === '上午') {
      return hour;
    } else if (meridiem === '下午' || meridiem === '晚上') {
      return hour + 12;
    } else {
      // '中午'
      return hour >= 11 ? hour : hour + 12;
    }
  },
  meridiem: function meridiem(hour, minute) {
    var hm = hour * 100 + minute;

    if (hm < 600) {
      return '凌晨';
    } else if (hm < 900) {
      return '早上';
    } else if (hm < 1130) {
      return '上午';
    } else if (hm < 1230) {
      return '中午';
    } else if (hm < 1800) {
      return '下午';
    } else {
      return '晚上';
    }
  },
  calendar: {
    sameDay: '[今天]LT',
    nextDay: '[明天]LT',
    nextWeek: '[下]ddddLT',
    lastDay: '[昨天]LT',
    lastWeek: '[上]ddddLT',
    sameElse: 'L'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
  ordinal: function ordinal(number, period) {
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
        return number + '日';

      case 'M':
        return number + '月';

      case 'w':
      case 'W':
        return number + '周';

      default:
        return number;
    }
  },
  relativeTime: {
    future: '%s内',
    past: '%s前',
    s: '几秒',
    ss: '%d秒',
    m: '1分钟',
    mm: '%d分钟',
    h: '1小时',
    hh: '%d小时',
    d: '1天',
    dd: '%d天',
    M: '1个月',
    MM: '%d个月',
    y: '1年',
    yy: '%d年'
  },
  week: {
    // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
    dow: 1,
    // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.

  }
});
var _fmt = 'YYYY-MM-DD HH:mm:ss';
var DateUtils = {
  arrangeList: function arrangeList(hm) {
    if (hm === 0) {
      return '上班时间';
    } else if (hm === 1) {
      return '加班时间';
    } else if (hm === 2) {
      return '休息时间';
    }
  },
  meridiem: function meridiem(minute) {
    var hm = minute;

    if (hm < 600) {
      return '凌晨';
    } else if (hm < 900) {
      return '早上';
    } else if (hm < 1130) {
      return '上午';
    } else if (hm < 1230) {
      return '中午';
    } else if (hm < 1800) {
      return '下午';
    } else {
      return '晚上';
    }
  },
  formatDate: function formatDate(date, fmt) {
    return moment(date, _fmt).format(fmt);
  },
  formatTimeStamp: function formatTimeStamp(date) {
    return moment(date).format('L');
  },
  getWeekTimeStamp: function getWeekTimeStamp(date) {
    //根据时间戳获取当前是周几
    return moment(date).day();
  },
  getTimeStamp: function getTimeStamp(date) {
    //根据当前时间获取当前时间戳
    return moment(date, _fmt).unix();
  },
  getFullDate: function getFullDate(date) {
    return moment(date, _fmt).format('L');
  },
  getFullDateCn: function getFullDateCn(date) {
    return moment(date, _fmt).format('LL');
  },
  addDate: function addDate(date, days) {
    if (days == undefined || days == '') {
      days = 0;
    }

    return moment(date, _fmt).add(days, 'days').format('L');
  },
  addMonth: function addMonth(date, months) {
    if (months == undefined || months == '') {
      months = 0;
    }

    return moment(date, _fmt).add(months, 'months').format('L');
  },
  formatDateMonth: function formatDateMonth(date) {
    return moment(date, _fmt).format('YYYY-MM');
  },
  getWeekDaysCn: function getWeekDaysCn(date, days) {
    if (days == undefined || days == '') {
      days = 0;
    }

    return moment(date, _fmt).add(days, 'days').format('ddd');
  },
  getWeekCn: function getWeekCn(date) {
    return moment(date, _fmt).format('ddd');
  },
  getWeekCnOne: function getWeekCnOne(date) {
    return moment(date).format('dd');
  },
  getWeekCnThree: function getWeekCnThree(date) {
    return moment(date, _fmt).format('dddd');
  },
  getMonthMM: function getMonthMM(date) {
    return moment(date, _fmt).format('MM');
  },
  getMonth: function getMonth(date) {
    return moment(date, _fmt).format('M');
  },
  getYear: function getYear(date) {
    return moment(date, _fmt).format('Y');
  },
  getMonthEnd: function getMonthEnd(date) {
    var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'L';
    return moment(date, _fmt).endOf('month').format(fmt);
  },
  getMonthStart: function getMonthStart(date) {
    var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'L';
    return moment(date, _fmt).startOf('month').format(fmt);
  },
  getNextMonthStart: function getNextMonthStart(date, months) {
    if (months == undefined || months == '') {
      months = 0;
    }

    return moment(date, _fmt).add(months, 'months').startOf('month').format('L');
  },
  getMMDDHHmmss: function getMMDDHHmmss(date) {
    return moment(date, _fmt).format('MM-DD HH:mm:ss');
  },
  getMMDD: function getMMDD(date) {
    return moment(date, _fmt).format('MM-DD');
  },
  getYYYYMMDDHHmm: function getYYYYMMDDHHmm(date) {
    return moment(date, _fmt).format('YYYY-MM-DD HH:mm');
  },
  getYYYYMMDD: function getYYYYMMDD(date) {
    return moment(date, _fmt).format('YYYY-MM-DD');
  },
  getYYYYMM: function getYYYYMM(date) {
    return moment(date, _fmt).format('YYYY-MM');
  },
  getH: function getH(date) {
    return moment(date, _fmt).format('HH');
  },
  getonMonth: function getonMonth(date) {
    return moment(date, 'YYYY-MM-DD').subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  },
  gettheMonth: function gettheMonth(date) {
    return moment(date, 'YYYY-MM-DD').add(1, 'months').startOf('month').format('YYYY-MM-DD');
  },
  lastMonday: function lastMonday(data, weekOfday) {
    return moment(data).subtract(weekOfday - 1, 'days').format('YYYY-MM-DD');
  },
  lastsunday: function lastsunday(data, weekOfday) {
    return moment(data).add(7 - weekOfday, 'days').format('YYYY-MM-DD');
  },
  beforesunday: function beforesunday(data, weekOfday) {
    return moment(data).add(weekOfday, 'days').format('YYYY-MM-DD');
  },
  formatScmDate: function formatScmDate(date) {
    return (Number(date) / 60 < 10 ? 0 + '' + Number(date) / 60 : Number(date) / 60) + ':' + (Number(date) % 60 < 10 ? 0 + '' + Number(date) % 60 : Number(date) % 60);
  },
  formatScmTime: function formatScmTime(time) {
    var obj = time.split(':');
    var temp = '';

    for (var idx in time.split(':')) {
      temp = temp + ':' + (Number(obj[idx]) < 10 ? 0 + '' + Number(obj[idx]) : Number(obj[idx]));
    }

    return temp.substr(1);
  },
  getMinutesStamp: function getMinutesStamp(date) {
    return moment.duration(date).asMinutes();
  },
  addDateTime: function addDateTime(date, days) {
    if (days == undefined || days == '') {
      days = 0;
    }

    return moment(date, _fmt).add(days, 'days').format('L');
  },
  addMinuteTime: function addMinuteTime(time, val) {
    return moment(time).add(val, 'minutes').format('YYYY-MM-DD HH:mm');
  },
  subtractMinuteTime: function subtractMinuteTime(time, val) {
    return moment(time).subtract(val, 'minutes').format('YYYY-MM-DD HH:mm');
  },
  // 计算天数差的函数  不包括结束日期
  DateDiff: function DateDiff(sDate1, sDate2) {
    //sDate1和sDate2是2002-12-18格式
    var aDate, oDate1, oDate2, iDays;
    aDate = sDate1.split("-");
    oDate1 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0]); //转换为12-18-2002格式

    aDate = sDate2.split("-");
    oDate2 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0]);
    iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数

    return iDays;
  },

  /***
   * 本周的第一天时间
   * @date=new date()
   */
  getBeginWeek: function getBeginWeek(date) {
    if (typeof date == 'string') {
      date = date.replace(/-/g, "/");
    }

    var currentDate = new Date(date);
    var week = currentDate.getDay(); //一天的毫秒数

    var millisecond = 1000 * 60 * 60 * 24; //减去的天数

    var minusDay = week != 0 ? week - 1 : 6; //本周 周一

    var monday = new Date(currentDate.getTime() - minusDay * millisecond);
    return monday;
  },

  /***
   * 上周的开始
   */
  getBeginLastWeek: function getBeginLastWeek(date) {
    date = date.replace(/-/g, "/");
    var currentDate = new Date(date);
    var first = currentDate.getDate() - currentDate.getDay() - 6;
    var startDate = new Date(currentDate.setDate(first));
    return startDate;
  },

  /***
   * 本月的第一天时间
   */
  getBeginMonth: function getBeginMonth(date) {
    // date = date.replace(/\-/g, "/");
    var currentDate = new Date(date);
    var currentMonth = currentDate.getMonth(); //获得当前年份4位年

    var currentYear = currentDate.getFullYear(); //求出本月第一天

    var firstDay = new Date(currentYear, currentMonth, 1);
    return firstDay;
  },

  /***
   * 本月的最后一天时间
   */
  getEndMonth: function getEndMonth(date) {
    //获取当前时间
    // date = date.replace(/\-/g, "/");
    var currentDate = new Date(date);
    var fullYear = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月

    var endOfMonth = new Date(fullYear, month, 0);
    return endOfMonth;
  },

  /***
   * 上月的第一天时间
   */
  getBeginLastMonth: function getBeginLastMonth(date) {
    date = date.replace(/-/g, "/"); //获取当前时间

    var currentDate = new Date(date); //获得当前月份0-11

    var currentMonth = currentDate.getMonth(); //获得当前年份4位年

    var currentYear = currentDate.getFullYear(); //获得上一个月的第一天

    var priorMonthFirstDay = this.getPriorMonthFirstDay(currentYear, currentMonth);
    return priorMonthFirstDay;
  },

  /***
   * 上月的最后一天时间
   */
  getEndLastMonth: function getEndLastMonth(date) {
    //获取当前时间
    date = date.replace(/-/g, "/");
    var currentDate = new Date(date); //获得当前月份0-11

    var currentMonth = currentDate.getMonth(); // //获得当前年份4位年
    // let currentYear = currentDate.getFullYear();
    //当为12月的时候年份需要加1
    //月份需要更新为0 也就是下一年的第一个月

    if (currentMonth == 11) {
      // currentYear++;
      currentMonth = 0; //就为
    } else {
      //否则只是月份增加,以便求的下一月的第一天
      currentMonth++;
    } //一天的毫秒数


    var millisecond = 1000 * 60 * 60 * 24; //求出上月的最后一天

    var lastDay = new Date(this.getBeginMonth().getTime() - millisecond);
    return lastDay;
  },
  getPriorMonthFirstDay: function getPriorMonthFirstDay(year, month) {
    //年份为0代表,是本年的第一月,所以不能减
    if (month == 0) {
      month = 11; //月份为上年的最后月份

      year--; //年份减1

      return new Date(year, month, 1);
    } //否则,只减去月份


    month--;
    return new Date(year, month, 1);
  },
  // 计算下个月
  getNextMonth: function getNextMonth(date) {
    var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份

    var month = arr[1]; //获取当前日期的月份

    var day = arr[2]; //获取当前日期的日
    // let days = new Date(year, month, 0);
    // days = days.getDate(); //获取当前日期中的月的天数

    var year2 = year;
    var month2 = parseInt(month) + 1;

    if (month2 == 13) {
      year2 = parseInt(year2) + 1;
      month2 = 1;
    }

    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();

    if (day2 > days2) {
      day2 = days2;
    }

    if (month2 < 10) {
      month2 = '0' + month2;
    }

    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
  },
  // 计算是否是今天过去的时间
  oldDate: function oldDate(date) {
    date = date.replace(/-/g, "/");
    var date1 = new Date(date); //要对比的时间

    var date2 = new Date(); //获取当前时间对象

    var num = 24 * 60 * 60 * 1000; //一天的毫秒数

    var cha = date2.getTime() - date1.getTime(); //两个时间的毫秒差

    if (cha > 0) {
      if (cha > num) {
        //  alert("不是今天的过去时间")
        return true;
      } else if (date1.getDate() != date2.getDate()) {
        //  alert("不是今天的过去时间")
        return true;
      } else {
        return "today"; // alert("是今天里的过去时间")
      }
    } else if (cha < 0) {
      return false; // alert("是未来时间")
    } else {
      return "today"; // alert("是现在")
    }
  },

  /** function 开始时间后一个小时
   *   lwt
   *  2020-07-14
   **/
  dateEnd: function dateEnd(val) {
    var atime = val.replace(/-/g, '/');
    var now = new Date(atime);
    var time = now.getTime() + 1000 * 60 * 60;
    var dd = new Date(time); // let dd = new Date()

    var seperator1 = '-';
    var seperator2 = ':'; //   dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期

    var y = dd.getFullYear();
    var m = dd.getMonth() + 1; //获取当前月份的日期

    var d = dd.getDate();
    var h = dd.getHours();
    var mm = dd.getMinutes(); // let s = dd.getSeconds()

    m = m > 9 ? m : '0' + m; //格式化时间

    d = d > 9 ? d : '0' + d;
    h = h > 9 ? h : '0' + h;
    mm = mm > 9 ? mm : '0' + mm;
    return y + seperator1 + m + seperator1 + d + ' ' + h + seperator2 + mm;
  },

  /** function 计算相差几个小时
   *   lwt
   *  2020-10-20
   **/
  twoTimeInterval: function twoTimeInterval(startTime, endTime) {
    // 开始时间
    var d1 = startTime.replace(/-/g, "/");
    var date1 = new Date(d1); // 结束时间

    var d2 = endTime.replace(/-/g, "/");
    var date2 = new Date(d2); // 时间相差秒数

    var dateDiff = date2.getTime() - date1.getTime();
    return Math.floor(dateDiff / 1000 / 60 / 60);
  }
};
export default DateUtils;