/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2020-03-02 15:11:43
 * @LastEditors: sueRimn
 * @LastEditTime: 2020-04-23 10:06:32
 */
var Params = {
  // 外勤调整范围 默认1000
  radius: 1000,
  // 是否多企业模式
  mode: '0',
  // 定位考勤、外勤是否拍照 默认0
  attence: '0',
  // 定位考勤是否显示外勤 默认1
  outside: '1',
  // 是否显示解绑按钮 默认1
  unlock: '1',
  // 通知公告是否标准 默认1
  message: '1',
  // 审批人是否可选 默认1
  approve: '1',
  // 抄送人是否可选 默认1
  copyuser: '1',
  // 申请审批是否根据员工员工权限
  appcopy: '0',
  // 请假时长是否显示 默认1
  leaveLength: '1',
  // 请假字数是否限制20字
  Txtlength: '0',
  //0是不限制  1 是限制
  // 外勤显示内容
  commonList: [{
    key: '1',
    value: '作为上班打卡'
  }, {
    key: '2',
    value: '作为下班打卡'
  }],
  // 我的显示样式
  mytheme: '0',
  // 大屏取餐号  默认0 传值1 是取餐号
  foodsNum: '0',
  //郑州新中新电子-探亲假-  时间限制10天   默认是0  1是限制
  homeLeaveTime: '0',
  //益阳铁路-订餐--  增加打包  默认是0  1是限制
  TakeFood: '0',
  // 消费积分光盘行动-- 默认是元 默认是0 1积分
  expendNum: '0',
  // 富维是否显示加班餐，默认是不显示 默认是0  1是显示
  overtimeMeals: '0',
  //是否开启访客登记的模糊查询 默认0 不开启
  NameSearch: '0',
  setRadius: function setRadius(radius) {
    this.radius = radius;
  },
  setMode: function setMode(mode) {
    this.mode = mode;
  },
  setAttence: function setAttence(attence) {
    this.attence = attence;
  },
  setOutside: function setOutside(outside) {
    this.outside = outside;
  },
  setUnlock: function setUnlock(unlock) {
    this.unlock = unlock;
  },
  setMessage: function setMessage(message) {
    this.message = message;
  },
  setApprove: function setApprove(approve) {
    this.approve = approve;
  },
  setCopyuser: function setCopyuser(copyuser) {
    this.copyuser = copyuser;
  },
  setAppcopy: function setAppcopy(appcopy) {
    this.appcopy = appcopy;
  },
  setLeaveLength: function setLeaveLength(leaveLength) {
    this.leaveLength = leaveLength;
  },
  setCommonList: function setCommonList(commonList) {
    this.commonList = commonList;
  },
  setMytheme: function setMytheme(mytheme) {
    this.mytheme = mytheme;
  },
  setTxtLength: function setTxtLength(Txtlength) {
    //请假
    this.Txtlength = Txtlength;
  },
  setFoodsNum: function setFoodsNum(num) {
    //大屏取餐
    this.foodsNum = num;
  },
  setHomeLeave: function setHomeLeave(num) {
    //探亲假
    this.homeLeaveTime = num;
  },
  setTakeFood: function setTakeFood(num) {
    //打包
    this.TakeFood = num;
  },
  setExpendNum: function setExpendNum(num) {
    //积分
    this.expendNum = num;
  },
  setOvertimeMeals: function setOvertimeMeals(num) {
    this.overtimeMeals = num; //加班餐
  },
  setNameSearch: function setNameSearch(num) {
    this.NameSearch = num;
  }
};
export default Params;