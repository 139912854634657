/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Meal = {
  getMealTypeList: function getMealTypeList(params) {
    return http.post("".concat(base.dev, "/edDineMealSetting/selectDayMeal"), params);
  },
  getFoodList: function getFoodList(params) {
    return http.post("".concat(base.dev, "/edDineFood/selectFoodList"), params);
  },
  getAddressList: function getAddressList(params) {
    return http.post("".concat(base.dev, "/edDineAddress/selectListByEntity"), params);
  },
  getOrderInfo: function getOrderInfo(userSerial) {
    return http.get("".concat(base.dev, "/edDineOrder/selectLastOrder"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  getPlace: function getPlace(placeId) {
    return http.get("".concat(base.dev, "/edDineMealSetting/sel-place-setting"), {
      params: {
        placeId: placeId
      }
    });
  },
  getAccountRecords: function getAccountRecords(params) {
    return http.post("".concat(base.dev, "/xfMx/selectAccountRecord"), params);
  },
  insertOrder: function insertOrder(params) {
    return http.put("".concat(base.dev, "/edDineOrder/insert"), params);
  },
  getExplain: function getExplain(placeId) {
    return http.get("".concat(base.dev, "/edDineShow/selectByPrimaryKey"), {
      params: {
        placeId: placeId
      }
    });
  },
  getOrderRecords: function getOrderRecords(params) {
    return http.post("".concat(base.dev, "/edDineOrder/selectOrderListPageByEntity"), params);
  },
  getOrderDetail: function getOrderDetail(orderId, mealId) {
    return http.get("".concat(base.dev, "/edDineOrder/selectOrderDetail"), {
      params: {
        orderId: orderId,
        mealId: mealId
      }
    });
  },
  selectDayState: function selectDayState(params) {
    return http.post("".concat(base.dev, "/edDineMealSetting/selectDayState"), params);
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/edDineOrderInfo/updateByPrimaryKey"), params);
  },
  otderAppraiseKey: function otderAppraiseKey(params) {
    return http.post("".concat(base.dev, "/edDineOrderInfo/insertOrderEvaluation"), params);
  },
  otderByUserSsion: function otderByUserSsion(userSerial) {
    return http.get("".concat(base.dev, "/dineUserRole/selectOrderAuthority"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  shareOrder: function shareOrder(params) {
    return http.post("".concat(base.dev, "/edDineOrder/shareOrder"), params);
  },
  getShareList: function getShareList(params) {
    return http.post("".concat(base.dev, "/edDineOrder/selectShareOrderListPageByEntity "), params);
  },
  receiveShareOrder: function receiveShareOrder(params) {
    return http.post("".concat(base.dev, "/edDineOrder/receiveOrder "), params);
  },
  // getUserInfo(params) {
  //   return http.post(`${base.dev}/user/get-user-info`,params);
  // },
  getUserInfo: function getUserInfo(code) {
    return http.get("".concat(base.dev, "/user/get-user-info"), {
      params: {
        code: code
      }
    });
  },
  // 黑龙江中医药大学附属医院定制，获取楼宇
  getBuildings: function getBuildings() {
    return http.get("".concat(base.dev, "/edDineAddress/getFlood"));
  }
};
export default Meal;