/* this file is transformed by vux-loader */
/* eslint-disable */
import DateUtils from './date';
import Position from './position';
import Tools from './tools';
import Store from './store';
import Custom from './custom';
import RegExp from './regexp';
import DingTalk from './dingtalk';
import WeiXin from './weixin';
var utils = {
  DateUtils: DateUtils,
  Position: Position,
  Tools: Tools,
  Store: Store,
  Custom: Custom,
  RegExp: RegExp,
  DingTalk: DingTalk,
  WeiXin: WeiXin
};
export default utils;