/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Hair = {
  getAccountInfo: function getAccountInfo(params) {
    return http.post("".concat(base.dev, "/haircut/getResidualCounts"), params); // return Promise.resolve({
    //   "code": "600",
    //   "msg": "操作成功",
    //   "data": {
    //     remainingTotal: 100,
    //     remainingMonth: 1,
    //   }
    // })
  },
  // 总览页获取列表
  getMainList: function getMainList(params) {
    return http.post("".concat(base.dev, "/haircut/selectAppointmentListByEntity"), params); // 预约查询
    // if (params.search.type === 0) {
    //   return jutils.delayVal({
    //     "code": "600", "msg": "操作成功", "data": {
    //       "records": [
    //         {
    //           "xh": 1001,
    //           "userSerial": 2000001,
    //           "userName": '张三',
    //           "depSerial": "10000",
    //           "depName": "一楼餐厅",
    //           "jlSj": "2020-02-12 15:16:58",
    //         },
    //       ], "totalRecord": 1, "totalPage": 1, "pageIndex": 1, "currenRecords": 1, "pageSize": 20
    //     }
    //   });
    // }
    // // 消费查询
    // else if (params.search.type === 1) {
    //   return jutils.delayVal({
    //     "code": "600", "msg": "操作成功", "data": {
    //       "records": [
    //         {
    //           "xh": 2001,
    //           "depSerial": "10000",
    //           "depName": "一楼餐厅",
    //           "jlSj": "2020-02-12 16:00:00",
    //         },
    //       ], "totalRecord": 1, "totalPage": 1, "pageIndex": 1, "currenRecords": 1, "pageSize": 20
    //     }
    //   });
    // }
    // // 无效的列表项
    // else {
    //   return Promise.reject(new Error("无效的列表项"));
    // }
  },
  getMxList: function getMxList(params) {
    return http.post("".concat(base.dev, "/haircut/selectHairCutConsumeSubsidyListByEntity"), params); // 消费明细
    // if (params.search.type === 0) {
    //   return jutils.delayVal({
    //     "code": "600", "msg": "操作成功", "data": {
    //       "records": [
    //         {
    //           "xh": 2001,
    //           "depSerial": "10000",
    //           "depName": "一楼餐厅",
    //           "jlSj": "2020-02-12 16:00:00",
    //         }
    //       ], "totalRecord": 1, "totalPage": 1, "pageIndex": 1, "currenRecords": 1, "pageSize": 20
    //     }
    //   });
    // }
    // // 充值明细
    // else if (params.search.type === 1) {
    //   return jutils.delayVal({
    //     "code": "600", "msg": "操作成功", "data": {
    //       "records": [
    //         {
    //           "xh": 2001,
    //           'addCount':10,
    //           "jlSj": "2020-02-12 16:00:00",
    //         }
    //       ], "totalRecord": 1, "totalPage": 1, "pageIndex": 1, "currenRecords": 1, "pageSize": 20
    //     }
    //   });
    // }
    // // 补贴明细
    // else if (params.search.type === 2) {
    //   return jutils.delayVal({
    //     "code": "600", "msg": "操作成功", "data": {
    //       "records": [
    //         {
    //           "xh": 2001,
    //           'subCount':20,
    //           "jlSj": "2020-02-12 16:00:00",
    //         }
    //       ], "totalRecord": 1, "totalPage": 1, "pageIndex": 1, "currenRecords": 1, "pageSize": 20
    //     }
    //   });
    // }
    // // 无效的列表项
    // else {
    //   return Promise.reject(new Error("无效的列表项"));
    // }
  },
  getMxInfo: function getMxInfo(params) {
    return http.post("".concat(base.dev, "/haircut/collectSum"), params); // return jutils.delayVal({
    //     "code": "600",
    //     "msg": "操作成功",
    //     "data": {
    //       newUsed:10, // 消费
    //       newAdd:2, // 充值
    //       newSub:100 // 补贴
    //     }
    //   }
    // );
  }
};
export default Hair;