/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Appr = {
  // ---获取审批节点  lwt  07-03
  getApplicants: function getApplicants(params) {
    return http.get("".concat(base.dev, "/approveApplyRecord/getApplicants"), {
      params: params
    });
  },
  // --申请提交
  apply: function apply(params) {
    return http.post("".concat(base.dev, "/approveApplyRecord/apply"), params);
  },
  // --待审批列表/已审批列表接口
  approveList: function approveList(params) {
    return http.post("".concat(base.dev, "/approveRecordPerson/approveList"), params);
  },
  // --抄送我的 列表接口
  copymeList: function copymeList(params) {
    return http.post("".concat(base.dev, "/approveRecordPerson/copymeList"), params);
  },
  // --我申请的列表 0待审批 1审核中 2完成 3打回 4撤销
  applyList: function applyList(params) {
    return http.post("".concat(base.dev, "/approveRecordPerson/applyList"), params);
  },
  // --审核操作
  audit: function audit(params) {
    return http.post("".concat(base.dev, "/approveWorkflowRecords/auditReview"), params);
  },
  // -审核信息详情查看
  queryAuditInfo: function queryAuditInfo(params) {
    return http.post("".concat(base.dev, "/approveApplyRecord/queryAuditInfo"), params);
  },
  // 撤销
  undo: function undo(params) {
    return http.post("".concat(base.dev, "/approveRecordPerson/undo"), params);
  },
  // 延时加班(/外勤) 获取审批节点---一汽富维  （新增接口）lwt2020-10-21
  getApplicantsDealy: function getApplicantsDealy(params) {
    return http.get("".concat(base.dev, "/approveApplyRecord/getApplicants-dealy"), {
      params: params
    });
  },
  // 申请加班提交(/外勤)（新增接口）lwt2020-10-21
  outtimeapply: function outtimeapply(params) {
    return http.post("".concat(base.dev, "/approveApplyRecord/dealy-over-time"), params);
  },
  // 获取可以销假单的列表
  leaveoff: function leaveoff(params) {
    return http.post("".concat(base.dev, "/approveApplyRecord/apply-leaveoff-list"), params);
  }
};
export default Appr;