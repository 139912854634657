/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.concat.js";
import base from '../base';
import http from '../http';
var User = {
  getWxOpenIdByCode: function getWxOpenIdByCode(code, companyId) {
    return http.get("".concat(base.dev, "/user/getWxOpenIdByCode"), {
      params: {
        code: code
      },
      headers: {
        'CompanyId': companyId
      }
    });
  },
  getWxOpenIdByCodeVisitor: function getWxOpenIdByCodeVisitor(code, companyId) {
    return http.get("".concat(base.dev, "/user/getWxOpenIdByVisitor"), {
      params: {
        code: code
      },
      headers: {
        'CompanyId': companyId
      }
    });
  },
  getUserInfo: function getUserInfo(userSerial) {
    return http.get("".concat(base.dev, "/userInfo/selectByPrimaryKey"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  getUser: function getUser(userSerial) {
    // userBase/selectUserFacePhoto
    // userBase/selectByPrimaryKey
    return http.get("".concat(base.dev, "/userBase/selectUserFacePhoto"), {
      params: {
        userSerial: userSerial
      }
    });
  },
  addFace: function addFace(params) {
    return http.post("".concat(base.dev, "/userBase/updateByPrimaryKey"), params);
  },
  delFace: function delFace(userSerial) {
    // return http.post(`${base.dev}/userBase/deleteFace?userSerial=${userSerial}`);
    return http.post("".concat(base.dev, "/userBase/deleteFace?userSerial=").concat(userSerial));
  },
  delImg: function delImg(params) {
    return http.post("".concat(base.dev, "/userBase/deleteFaceOrPhoto"), params);
  },
  addFacePhoto: function addFacePhoto(params) {
    return http.post("".concat(base.dev, "/userBase/updatePhotoFace"), params);
  },
  selectVisUserByDept: function selectVisUserByDept(params) {
    //2020-12-15 输入员工姓名进行查询
    return http.post("".concat(base.dev, "/userBase/selectVisUserByDept"), params);
  },
  //user/getAppIdSecret
  getUserAppId: function getUserAppId() {
    return http.post("".concat(base.dev, "/user/getAppIdSecret"));
  }
};
export default User;