/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Meeting = {
  getMeetingList: function getMeetingList(params) {
    return http.post("".concat(base.dev, "/tkqRoom/selectListPageByEntity"), params);
  },
  selectTkqRoomInfo: function selectTkqRoomInfo(params) {
    return http.post("".concat(base.dev, "/tkqRoom/selectTkqRoomInfo"), params);
  },
  getMyMeetingDateList: function getMyMeetingDateList(params) {
    return http.post("".concat(base.dev, "/meet/selectDateListByEntity"), params);
  },
  getRoomMeetingList: function getRoomMeetingList(params) {
    return http.post("".concat(base.dev, "/meet/selectMeetListByRoom"), params);
  },
  getMyMeetingList: function getMyMeetingList(params) {
    return http.post("".concat(base.dev, "/meet/selectMyMeetListByDate"), params);
  },
  getMeetingDetail: function getMeetingDetail(bh, userSerial) {
    return http.get("".concat(base.dev, "/meet/selectByPrimaryKey"), {
      params: {
        bh: bh,
        userSerial: userSerial
      }
    });
  },
  insertMeetingOrder: function insertMeetingOrder(params) {
    return http.post("".concat(base.dev, "/meet/insert"), params);
  },
  deleteMeetingOrder: function deleteMeetingOrder(bh, userSerial) {
    return http.delete("".concat(base.dev, "/meet/deleteByPrimaryKey"), {
      params: {
        bh: bh,
        userSerial: userSerial
      }
    });
  },
  getApproveList: function getApproveList(params) {
    return http.post("".concat(base.dev, "/roomGly/selectListByEntity"), params);
  },
  selectMySubmit: function selectMySubmit(params) {
    return http.post("".concat(base.dev, "/meet/selectMySubmit"), params);
  },
  selectMyApprove: function selectMyApprove(params) {
    return http.post("".concat(base.dev, "/meet/selectMyApprove"), params);
  },
  selectMyCopy: function selectMyCopy(params) {
    return http.post("".concat(base.dev, "/meet/selectMyCopy"), params);
  },
  approveMeeting: function approveMeeting(params) {
    return http.post("".concat(base.dev, "/meet/approveMeeting"), params);
  },
  isMyApprove: function isMyApprove(params) {
    return http.post("".concat(base.dev, "/meet/isMyApprove"), params);
  },
  //会议详情tkqRoom/selectTkqRoomDetailByEntity
  selectMeetingDetail: function selectMeetingDetail(params) {
    return http.post("".concat(base.dev, "/tkqRoom/selectTkqRoomDetailByEntity"), params);
  },
  //会议详情页面meet/selectByPrimaryKey
  selectByPrimaryDetail: function selectByPrimaryDetail(params) {
    return http.get("".concat(base.dev, "/meet/selectByPrimaryKey"), {
      params: params
    });
  },
  //meet/approveMeeting 会议接口审批
  meetApproveMeeting: function meetApproveMeeting(params) {
    return http.post("".concat(base.dev, "/meet/approveMeeting"), params);
  },
  //取消会议 撤销会议 meet/deleteByPrimaryKey
  meetDelete: function meetDelete(params) {
    return http.post("".concat(base.dev, "/meet/deleteByPrimaryKey"), params);
  },
  //meet/getWeChatQRCode 获取二维码
  getWeChatQRCode: function getWeChatQRCode(params) {
    return http.post("".concat(base.dev, "/meet/getWeChatQRCode"), params);
  },
  ///user/getWxUserInfo
  getUserInfo: function getUserInfo(params) {
    return http.get("".concat(base.dev, "/user/getWxUserInfo"), {
      params: params
    });
  },
  //meet/meetApply
  subMeetApply: function subMeetApply(params) {
    return http.post("".concat(base.dev, "/meet/meetApply"), params);
  },
  ///visRegisterNew/getVisQrCodeStr
  getVisQrCodeStr: function getVisQrCodeStr(params) {
    return http.post("".concat(base.dev, "/visRegisterNew/getVisQrCodeStr"), params);
  },
  //api/tkqRoom/selelctIsItAuthorized
  selectIsAuthorized: function selectIsAuthorized(params) {
    return http.post("".concat(base.dev, "/tkqRoom/selelctIsItAuthorized"), params);
  },
  //selectMeetDetailByMeetBh
  selectMeetBh: function selectMeetBh(params) {
    return http.get("".concat(base.dev, "/meet/selectMeetDetailByMeetBh"), {
      params: params
    });
  },
  //获取会议二维码 getWeiXinPublicQR
  getPbulicQR: function getPbulicQR(params) {
    return http.get("".concat(base.dev, "/user/getWeiXinPublicQR"), {
      params: params
    });
  }
};
export default Meeting;