/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
export default {
  // 获取部门列表
  getDepList: function getDepList(params) {
    return http.post("".concat(base.dev, "/warnSendUser/getWarnDoorList"), params); //return http.post(`${base.dev}/xfMx/accountSum`, params);
    // return jutils.delayVal({
    //   "code": "600",
    //   "msg": "操作成功",
    //   "data": {
    //     list: [
    //       {
    //         gateBh: "10001",
    //         doorName: "东门",
    //       },
    //       {
    //         gateBh: "10002",
    //         doorName: "西门",
    //       },
    //       {
    //         gateBh: "10003",
    //         doorName: "南门",
    //       },
    //     ]
    //   }
    // })
  },
  // 总览页获取列表
  getTempRecord: function getTempRecord(params) {
    return http.post("".concat(base.dev, "/warnSendUser/getWarnHealthInfoList"), params);
  },
  // 获取消息详情
  getMsgInfo: function getMsgInfo(params) {
    return http.post("".concat(base.dev, "/warnSendUser/selectWarnHealthInfo"), params);
  },
  // 提交信息
  commitModify: function commitModify(params) {
    return http.post("".concat(base.dev, "/warnSendUser/updateWarnHealthInfo"), params);
  }
};