/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Info = {
  addInfoFeedback: function addInfoFeedback(params) {
    return http.put("".concat(base.dev, "/infoFeedback/insert"), params);
  },
  getInvoiceInfo: function getInvoiceInfo(xh) {
    return http.get("".concat(base.dev, "/invoiceInfo/selectByPrimaryKey"), {
      params: {
        xh: xh
      }
    });
  },
  getInfoList: function getInfoList(params) {
    return http.post("".concat(base.dev, "/infoFeedback/selectListPageByEntity"), params);
  },
  getInfoDetail: function getInfoDetail(xh) {
    return http.get("".concat(base.dev, "/infoFeedback/selectByPrimaryKey"), {
      params: {
        xh: xh
      }
    });
  }
};
export default Info;