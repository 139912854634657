/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Attence = {
  insertOutSide: function insertOutSide(params) {
    return http.post("".concat(base.dev, "/attence/addWq"), params);
  },
  getRange: function getRange(params) {
    return http.post("".concat(base.dev, "/attence/getRange"), params);
  },
  insertAttenct: function insertAttenct(params) {
    return http.post("".concat(base.dev, "/attence/addKq"), params);
  },
  getBanci: function getBanci(params) {
    return http.post("".concat(base.dev, "/attence/newGetBanci"), params);
  },
  getToday: function getToday(params) {
    return http.post("".concat(base.dev, "/attence/getKq"), params);
  },
  getKqDetail: function getKqDetail(params) {
    return http.post("".concat(base.dev, "/attence/getKqDetail"), params);
  },
  getAttenceSum: function getAttenceSum(params) {
    return http.post("".concat(base.dev, "/attence/kqHzNew"), params);
  },
  getAttenceRecord: function getAttenceRecord(params) {
    return http.post("".concat(base.dev, "/attence/newKqJl"), params);
  },
  getAttenceDayRecord: function getAttenceDayRecord(params) {
    return http.post("".concat(base.dev, "/attence/getKqjl"), params);
  },
  getAttenceDetail: function getAttenceDetail(params) {
    return http.post("".concat(base.dev, "/attence/newKqMx"), params);
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/attence/updateWqByPrimaryKeySelective"), params);
  },
  selectListPageByEntity: function selectListPageByEntity(params) {
    return http.post("".concat(base.dev, "/attence/selectWqListByEntity"), params);
  },
  selectWxByPrimaryKey: function selectWxByPrimaryKey(id) {
    return http.get("".concat(base.dev, "/attence/selectWxByPrimaryKey"), {
      params: {
        id: id
      }
    });
  },
  getKqInfo: function getKqInfo(bh) {
    return http.get("".concat(base.dev, "/attence/getKqInfo"), {
      params: {
        bh: bh
      }
    });
  },
  getSelectDepInfo: function getSelectDepInfo(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectDepInfo"), params);
  },
  getSelectUserInfo: function getSelectUserInfo(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectUserInfo"), params);
  },
  getSelectDepAll: function getSelectDepAll(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectDepAll"), params);
  },
  getSelectRecordByUser: function getSelectRecordByUser(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectRecordByUser"), params);
  },
  getSelectUserList: function getSelectUserList(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectUserList"), params);
  },
  getSelectUserBanci: function getSelectUserBanci(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectUserBanci"), params);
  },
  getSelectDepBanci: function getSelectDepBanci(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectDepBanci"), params);
  },
  getSelectUserNums: function getSelectUserNums(params) {
    return http.post("".concat(base.dev, "/SHAttenceController/selectUserNums"), params);
  },
  //  /kqSetting/getValues 判断考勤是否需要打卡
  getKqSettting: function getKqSettting() {
    return http.get("".concat(base.dev, "/kqSetting/getValues"));
  }
};
export default Attence;