/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Repairs = {
  insert: function insert(params) {
    return http.put("".concat(base.dev, "/repairInfo/insert"), params);
  },
  updateByPrimaryKey: function updateByPrimaryKey(params) {
    return http.post("".concat(base.dev, "/repairInfo/updateByPrimaryKey"), params);
  },
  selectListPageByEntity: function selectListPageByEntity(params) {
    return http.post("".concat(base.dev, "/repairInfo/selectListPageByEntity"), params);
  },
  selectSumInfoByEntity: function selectSumInfoByEntity(params) {
    return http.post("".concat(base.dev, "/repairInfo/selectSumInfoByEntity"), params);
  },
  selectByPrimaryKey: function selectByPrimaryKey(id) {
    return http.get("".concat(base.dev, "/repairInfo/selectByPrimaryKey"), {
      params: {
        id: id
      }
    });
  },
  selectListByEntity: function selectListByEntity(params) {
    return http.post("".concat(base.dev, "/repairTypeParam/selectListByEntity"), params);
  }
};
export default Repairs;