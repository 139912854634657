/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.slice.js";
import * as dd from 'dingtalk-jsapi';
import Vue from 'vue';
var Tools = {
  httpError: function httpError() {
    this.loadHide();
  },
  confirm: function confirm(confirmCallback, tip) {
    var title = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '提示';
    var inputFlag = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var confirmButtonText = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '确定';
    var cancelButtonText = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '取消';
    window.vm.$vux.confirm.show({
      showInput: inputFlag,
      title: title,
      confirmText: confirmButtonText,
      cancelText: cancelButtonText,
      content: tip,
      onConfirm: function onConfirm(value) {
        confirmCallback(value);
      },
      onCancel: function onCancel() {}
    });
  },
  loadShow: function loadShow(msg) {
    if (msg !== undefined) {
      window.vm.$vux.loading.show(msg);
    } else {
      window.vm.$vux.loading.show(window.vm.$t('http.loading'));
    }
  },
  loadHide: function loadHide() {
    window.vm.$vux.loading.hide();
  },
  toastShow: function toastShow(msg) {
    window.vm.$vux.toast.show({
      text: msg,
      type: 'text',
      isShowMask: true,
      width: 'auto'
    });
  },
  toastCustom: function toastCustom(code, msg) {
    window.vm.$vux.toast.show({
      text: msg,
      type: +code === Vue.$code.success ? 'success' : 'cancel',
      isShowMask: true
    });
  },
  toastSuccess: function toastSuccess(msg) {
    window.vm.$vux.toast.show({
      text: msg,
      type: 'success',
      isShowMask: true
    });
  },
  toastCancel: function toastCancel(msg) {
    window.vm.$vux.toast.show({
      text: msg,
      type: 'cancel',
      isShowMask: true
    });
  },
  toastHide: function toastHide() {
    window.vm.$vux.toast.hide();
  },
  optFailed: function optFailed() {
    this.toastShow(window.vm.$t('http.failed'));
  },
  optSuccess: function optSuccess() {
    this.toastShow(window.vm.$t('http.success'));
  },
  getQueryString: function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  getEnv: function getEnv(callback) {
    var userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('miniprogram') > -1) {
      //微信小程序
      callback(0);
    } else if (userAgent.indexOf('micromessenger') > -1) {
      //微信公众号
      callback(1);
    } else if (dd.env.platform != 'notInDingTalk') {
      //钉钉
      callback(2);
    } else {
      callback(3);
    }
  },
  getSys: function getSys(callback) {
    var userAgent = window.navigator.userAgent;

    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(userAgent)) {
      if (userAgent.indexOf('iPhone') > -1) {
        callback(0);
      } else {
        callback(1);
      }
    } else {
      callback(9);
    }
  },
  pushHistory: function pushHistory() {
    var state = {
      title: 'title',
      url: '#'
    };
    window.history.pushState(state, state.title, state.url);
  },
  isnull: function isnull(obj) {
    var val = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

    if (obj) {
      return obj;
    } else {
      return val;
    }
  },
  isArray: function isArray(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  },
  getUrlParams: function getUrlParams(name) {
    var userNo = '';
    var arr = [];
    var f = ['/', '#', '&', '%'];
    var url = decodeURIComponent(window.location.href);

    if (url.indexOf(name) != -1) {
      var params = url.split(name + '=')[1];

      for (var i = 0; i < params.length; i++) {
        for (var j = 0; j < f.length; j++) {
          if (params[i] == f[j]) arr.push(i);
        }
      }

      userNo = params.slice(0, Math.min.apply(Math, arr));
    }

    return userNo;
  },
  // 修改vux日期组件 二月可选择到30，31日问题
  repairDate: function repairDate(newVal) {
    var str = newVal.substr(5);
    var year = newVal.substr(0, 4);

    if (str === '02-29' || str === '02-30' || str === '02-31') {
      if (+year % 100 !== 0 && +year % 4 === 0 || +year % 400 === 0) {
        return year + '-02-29';
      } else {
        return year + '-02-28';
      }
    } else {
      return newVal;
    }
  }
};
export default Tools;