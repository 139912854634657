/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Spend = {
  getMxRecord: function getMxRecord(params) {
    return http.post("".concat(base.dev, "/xfMx/selectRecordList"), params);
  },
  getXfRecord: function getXfRecord(params) {
    return http.post("".concat(base.dev, "/xfMx/collectSum"), params);
  },
  getMxFenRecord: function getMxFenRecord(params) {
    //2021-03-02 lwt  查询份消费明细 后台找建强
    return http.post("".concat(base.dev, "/xfMx/selectRecordListFen"), params);
  },
  // 份-  汇总
  getXfFenRecord: function getXfFenRecord(params) {
    //2021-03-02 lwt  后台找建强
    return http.post("".concat(base.dev, "/xfMx/collectSumFen"), params);
  },
  getRefund: function getRefund(params) {
    return http.post("".concat(base.dev, "/wxpay/recharge-refund-set"), params);
  },
  refund: function refund(params) {
    return http.post("".concat(base.dev, "/wxpay/wx-refund"), params);
  },
  getWaterRecordList: function getWaterRecordList(params) {
    return http.post("".concat(base.dev, "/order/selectWaterRecordList"), params);
  },
  getWaterRecordAll: function getWaterRecordAll(params) {
    return http.post("".concat(base.dev, "/order/collectSumWater"), params);
  },
  getXiaofeimingxi: function getXiaofeimingxi(params) {
    //{ params: { userSerial } }
    return http.get("".concat(base.dev, "/xfMx/sel-xf-detail"), {
      params: params
    });
  }
};
export default Spend;