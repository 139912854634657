/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";

/*
 * 避免刷新之后vuex被重置，在sessionStorage做一个备份
 this.$store.commit('xxx')
 */
var mutations = {
  mutationFoodList: function mutationFoodList(state, foodList) {
    state.foodList = foodList;
    window.vm.$utils.Store.setLocalItem('foodList', JSON.stringify(foodList));
  },
  emtylist: function emtylist(state) {
    //清空
    state.visList = [{
      id: Date.now(),
      obj: {}
    }];
  },
  removeVislist: function removeVislist(state, obj) {
    state.visList.forEach(function (item, i) {
      if (obj.id == item.id) {
        state.visList.splice(i, 1);

        if (state.visList.length == 0) {
          state.visList.push({
            id: Date.now(),
            obj: {}
          });
        }

        window.vm.$utils.Store.setItem('visList', JSON.stringify(state.visList));
      }
    });
  },
  addVislist: function addVislist(state) {
    state.visList.push({
      id: Date.now(),
      obj: {}
    });
    window.vm.$utils.Store.setItem('visList', JSON.stringify(state.visList));
  },
  vaddVislist: function vaddVislist(state, vobj) {
    state.visList[vobj.index].obj = vobj.obj;
    state.visList[vobj.index].obj.g = 1;
    state.visList[vobj.index].obj.udis = true; // state.visList[vobj.index].obj.facePhoto="";
    // state.visList[vobj.index].obj.healthPhoto="";

    window.vm.$utils.Store.setItem('visList', JSON.stringify(state.visList));
  },
  updatedViList: function updatedViList(state, visObj) {
    state.visList.forEach(function (item, i) {
      if (item.id == visObj.id) {
        state.visList[i].obj = visObj;
        state.visList[i].obj.g = 1;
        window.vm.$utils.Store.setItem('visList', JSON.stringify(state.visList));
      }
    });
  },
  updateVisThing: function updateVisThing(state, thingObj) {
    //存事由的
    state.visThing = thingObj;
  },
  setonlyReadThing: function setonlyReadThing(state, thingObj) {
    //存事由的--只读
    state.onlyReadThing = thingObj;
    this.$utils.Store.setItem('itemExamInfo', thingObj);
  },
  setexInfo: function setexInfo(state, exInfo) {
    //----存被访问人的---只读
    if (!exInfo.telePhone) {
      exInfo.telePhone = '未填写';
    }

    state.exInfo = exInfo;
  },
  upuserVInfo: function upuserVInfo(state, Obj) {
    //存被访问人
    state.userVInfo = Obj;
  },
  uploadImg: function uploadImg(state, src) {
    state.uploadImgSrc = src;
  },
  positionNum: function positionNum(state, obj) {
    state.isPosition['num'] = obj.code;
    state.isPosition['mediaId'] = obj.serverId;
  },
  setOutSide: function setOutSide(state, obj) {
    state.outSideItem = obj;
  },
  setItemMsg: function setItemMsg(state, obj) {
    state.itemMsg = obj;
  },
  setQuliatyInfo: function setQuliatyInfo(state, obj) {
    state.uploadQualityInfo = obj;
  }
};
export default mutations;