/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
var BMap = window.BMap;
var BMAP_STATUS_SUCCESS = window.BMAP_STATUS_SUCCESS;
var Position = {
  initPoint: null,
  drawCircle: function drawCircle(map, point, radius) {
    var circle = new BMap.Circle(point, radius, {
      enableMassClear: true,
      strokeColor: 'red',
      strokeStyle: 'solid',
      strokeWeight: '10'
    });
    map.addOverlay(circle);
  },
  getMarkerBd: function getMarkerBd(container, longitude, latitude, zoom, dragFlag, callback) {
    if (longitude == null || latitude == null) {
      if (callback !== null && typeof callback === 'function') {
        callback(null);
      }
    }

    var that = this;
    var map = new BMap.Map(container);
    var geolocationControl = new BMap.GeolocationControl({
      showAddressBar: false
    });
    geolocationControl.addEventListener('locationSuccess', function (point) {
      map.clearOverlays();
      that.initPoint = point.point;
      that.drawCircle(map, point.point, that.radius);
      that.getAddressBd(point.point, map, dragFlag, callback);
    });
    /* 此处获取百度中心点 */
    // map.addControl(geolocationControl);

    var point = new BMap.Point(longitude, latitude);
    map.centerAndZoom(new BMap.Point(116.331398, 39.897445), zoom);
    map.panTo(point);
    this.initPoint = point; // let geolocation = new BMap.Geolocation();
    // this.drawCircle(map, point, this.radius);
    // let AMap = window.AMap
    // map = new AMap.Map(container, {
    //     resizeEnable: true
    // });

    this.getAddressBd(point, map, dragFlag, callback);
  },
  getAddressBd: function getAddressBd(point, map, dragFlag, callback) {
    this.convertFrom([point.lng, point.lat], 'baidu', point, map, dragFlag, callback); // let that = this;
    // let myGeo = new BMap.Geocoder();
    // 根据坐标得到地址描述
    // myGeo.getLocation(point, function (result) {
    //     console.log('进入了getLocation')
    //     let address = '';
    //     let title = '';
    //     let info = '';
    //     let address2 = '';
    //     let addComp = result.addressComponents;
    //     let surround = result.surroundingPois;
    //     if (surround.length > 0) {
    //         title = surround[0].title;
    //         address = surround[0].address;
    //     } else {
    //         address = addComp.province +
    //             addComp.city +
    //             addComp.district +
    //             addComp.street +
    //             addComp.streetNumber;
    //     }
    //     address2 = addComp.province +
    //         addComp.city +
    //         addComp.district +
    //         addComp.street +
    //         addComp.streetNumber;
    //     let markerOptions = {
    //         raiseOnDrag: true,
    //     };
    //     let marker = new BMap.Marker(point, markerOptions); // 创建标注
    //     console.log('进入了创建标注')
    //     if (dragFlag === true) {
    //         marker.enableDragging();
    //         marker.addEventListener('dragend', (type) => {
    //             let offset = map.getDistance(that.initPoint, type.point).toFixed(2);
    //             if (offset > window.vm.$params.radius) {
    //                 that.getAddressBd(that.initPoint, map, dragFlag, callback);
    //             } else {
    //                 that.getAddressBd(type.point, map, dragFlag, callback);
    //             }
    //         });
    //     }
    //     console.log('if (dragFlag === true) ')
    //     map.clearOverlays();
    //     if (title !== '') {
    //         info = title + '（' + address + '）';
    //     } else {
    //         info = address;
    //     }
    //     let label = new BMap.Label(
    //         info, {
    //             offset: new BMap.Size(25, 1),
    //         },
    //     );
    //     label.setStyle({fontSize: '0.15rem'});
    //     marker.setLabel(label);
    //     map.addOverlay(marker); // 将标注添加到地图中
    //     if (callback !== null && typeof callback === 'function') {
    //         console.log('callback !== null && typeof callback')
    //         callback(
    //             {
    //                 address: title !== '' ? title : address,
    //                 address2: address2,
    //                 longitude: point.lng,
    //                 latitude: point.lat,
    //             },
    //         );
    //     }
    // });
  },
  // 坐标转换  百度坐标点转换 高德坐标
  convertFrom: function convertFrom(lnglat, type, point, map, dragFlag, callback) {
    var AMap = window.AMap;
    var that = this;
    AMap.convertFrom(lnglat, type, function (status, result) {
      if (result.info === 'ok') {
        var resLnglat = result.locations[0];
        var _AMap = window.AMap;
        var geocoder = new _AMap.Geocoder({
          city: "010",
          //城市设为北京，默认：“全国”
          radius: 1000 //范围，默认：500

        }); // 高德方法通过坐标解析为地址

        geocoder.getAddress([resLnglat.R, resLnglat.Q], function (status, result) {
          if (status === 'complete' && result.regeocode) {
            // var address = result.regeocode.formattedAddress;
            // document.getElementById('address').value = address;
            var markerOptions = {
              raiseOnDrag: true
            };
            var marker = new BMap.Marker(point, markerOptions); // 创建标注

            if (dragFlag === true) {
              marker.enableDragging();
              marker.addEventListener('dragend', function (type) {
                var offset = map.getDistance(that.initPoint, type.point).toFixed(2);

                if (offset > window.vm.$params.radius) {
                  that.getAddressBd(that.initPoint, map, dragFlag, callback);
                } else {
                  that.getAddressBd(type.point, map, dragFlag, callback);
                }
              });
            }

            map.clearOverlays(); // if (title !== '') {
            //     info = title + '（' + address + '）';
            // } else {
            //     info = address;
            // }

            var label = new BMap.Label(result.regeocode.formattedAddress, {
              offset: new BMap.Size(25, 1)
            });
            label.setStyle({
              fontSize: '0.15rem'
            });
            marker.setLabel(label);
            map.addOverlay(marker); // 将标注添加到地图中

            if (callback !== null && typeof callback === 'function') {
              callback({
                address: result.regeocode.formattedAddress,
                address2: result.regeocode.formattedAddress,
                longitude: point.lng,
                latitude: point.lat
              });
            }

            that.getCity();
          } else {
            console.log('根据经纬度查询地址失败');
          }
        }); // // 设置标签
        // m2.setLabel({
        //     offset: new AMap.Pixel(20, 20),
        //     content: "高德坐标系中首开广场（正确）"
        // });
      }
    });
  },
  isRange: function isRange(container, longitude, latitude, points) {
    var pointA = new BMap.Point(longitude, latitude);

    for (var i = 0; i < points.length; i++) {
      var pointB = new BMap.Point(points[i].longitude, points[i].latitude); // 创建点坐标B--签到地点

      var map = new BMap.Map(container);
      var lengtha = map.getDistance(pointA, pointB).toFixed(2); //获取两点距离,保留小数点后两位

      if (+lengtha < +points[i].range) {
        points[i].isTrue = true;
        return points[i];
      }
    }

    return false;
  },
  getCity: function getCity(container, longitude, latitude, zoom, dragFlag, callback) {
    if (longitude == null || latitude == null) {
      if (callback !== null && typeof callback === 'function') {
        callback(null);
      }
    } // let point = new BMap.Point(longitude, latitude);
    // // this.initPoint = point;
    // let myGeo = new BMap.Geocoder();
    // 根据坐标得到地址描述
    // myGeo.getLocation(point, function (result) {
    //     let city = result.addressComponents.city;
    //     if (callback !== null && typeof callback === 'function') {
    //         callback(city);
    //     }
    // });


    var AMap = window.AMap;

    if (callback !== null && typeof callback === 'function') {
      AMap.plugin('AMap.CitySearch', function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          callback(result.city);
        });
      });
    }
  },
  // ----------------------------------------
  getPath: function getPath(container, longitude, latitude, pioslists, zoom, callback) {
    var _this = this;

    if (longitude == null || latitude == null) {
      if (callback !== null && typeof callback === 'function') {
        callback(null);
      }
    }

    var map = new BMap.Map(container); // let map = new BMap.Map(container);

    map.enableScrollWheelZoom(true);
    map.centerAndZoom(new BMap.Point(longitude, latitude), zoom);
    var myIcon = new BMap.Icon(require('../assets/dot.png'), new BMap.Size(40, 40));
    var marker = new BMap.Marker(new BMap.Point(longitude, latitude), {
      icon: myIcon
    }); // 创建标注
    // let marker = new BMap.Marker(new BMap.Point(longitude, latitude));

    marker.imei = 1;
    map.addOverlay(marker); // 创建polyline对象

    var clearMap = setInterval(function () {
      var pois = [];
      var poisList = JSON.parse(sessionStorage.getItem('poisList')) || [];

      if (poisList.length == 0) {
        clearInterval(clearMap);
      }

      poisList.forEach(function (element, index) {
        pois.push(new BMap.Point(element.longitude, element.latitude));
        map.removeOverlay(pois[index].marker);
      });
      polyline && map.removeOverlay(polyline);

      _this.deletePoint(map, 1);

      var polyline = new BMap.Polyline(pois, {
        enableEditing: false,
        //是否启用线编辑，默认为false
        enableClicking: true,
        //是否响应点击事件，默认为true
        strokeWeight: '5',
        //折线的宽度，以像素为单位
        strokeOpacity: 0.8,
        //折线的透明度，取值范围0 - 1
        strokeColor: "#1AC082" //折线颜色

      }); //  this.getMarkerBd('allmap', longitude, latitude,14, true,()=>{})

      map.addOverlay(polyline);
      var endPois = pois[pois.length - 1];
      var point = new BMap.Point(endPois.lng, endPois.lat); //  BMap.Convertor.translate(point,0,translateCallback);
      // map.centerAndZoom(point, zoom);
      // 'http://developer.baidu.com/map/jsdemo/img/car.png'

      var myIcon = new BMap.Icon(require('../assets/dot.png'), new BMap.Size(40, 40));
      var marker = new BMap.Marker(point, {
        icon: myIcon
      }); // 创建标注
      // let marker = new BMap.Marker(new BMap.Point(longitude, latitude));

      map.setCenter(point); //  let infoWindow = new BMap.InfoWindow(sContent);

      marker.imei = 1;
      map.addOverlay(marker); // 将标注添加到地图中
    }, 3000);
  },
  // --移除所有的点
  deletePoint: function deletePoint(map) {
    var allOverlay = map.getOverlays(); //循坏所有点

    for (var i = 0; i < allOverlay.length; i++) {
      if (allOverlay[i].imei == 1) {
        map.removeOverlay(allOverlay[i]);
      }
    }
  },
  // ---------------------
  getPaths: function getPaths(container, longitude, latitude, pioslist, zoom, callback) {
    if (longitude == null || latitude == null) {
      if (callback !== null && typeof callback === 'function') {
        callback(null);
      }
    }

    var map = new BMap.Map(container); // 创建Map实例

    var pois = []; // 坐标点

    pioslist.map(function (item) {
      return pois.push(new BMap.Point(item.longitude, item.latitude));
    }); // 初始化地图中心点，这里我是取了点列表的第一个值作为中心点

    setTimeout(function () {
      var convertor = new BMap.Convertor();
      var pointArr = [];
      pointArr.push(pois[pois.length - 1]);
      convertor.translate(pointArr, 1, 5, function (data) {
        if (data.status === 0) {
          map.centerAndZoom(new BMap.Point(data.points[0].lng, data.points[0].lat), 16); // 初始化地图,设置中心点坐标和地图级别
        }
      });
    }, 100); // 百度地图坐标转换一次最多支持10个，需要将数据分割成二维数组形式，我这里写9个一组

    var num = 9;
    var times = Math.ceil(pois.length / num);
    var newArr = [];

    for (var i = 0; i <= times; i++) {
      if (i * num >= pois.length) {
        break;
      }

      newArr.push(pois.slice(i * num, (i + 1) * num));
    } // 批量转换点


    setTimeout(function () {
      var convertor = new BMap.Convertor();
      newArr.map(function (item) {
        // 转换方法convertor.translate(要转换的值arr,1,5,回调函数)
        convertor.translate(item, 1, 5, function (data) {
          // 这里的data是转换后的百度地图坐标
          if (data.status === 0) {
            for (var _i = 0; _i < data.points.length; _i++) {
              // 创建polyline对象，画折线
              var polyline = new BMap.Polyline(data.points, {
                enableEditing: false,
                //是否启用线编辑，默认为false
                enableClicking: true,
                //是否响应点击事件，默认为true
                strokeWeight: '8',
                //折线的宽度，以像素为单位
                strokeOpacity: 0.8,
                //折线的透明度，取值范围0 - 1
                strokeColor: "#00A0E9" //折线颜色

              });
              map.addOverlay(polyline); //增加折线
            } // 创建自定义图标点


            data.points.map(function (item, index) {
              if (index == data.points.length - 1) {
                var pt = item;
                var myIcon = new BMap.Icon('http://developer.baidu.com/map/jsdemo/img/car.png', new BMap.Size(52, 26));
                var marker = new BMap.Marker(pt, {
                  icon: myIcon
                }); // 创建标注
                //  let infoWindow = new BMap.InfoWindow(sContent);

                marker.id = 123;
                map.addOverlay(marker); // 将标注添加到地图中
              }
            });
          }
        });
      });
    }, 100);
  },
  getPositionPio: function getPositionPio() {
    var geolocation = new BMap.Geolocation();
    geolocation.getCurrentPosition(function () {
      if (this.getStatus() == BMAP_STATUS_SUCCESS) {
        var point = new BMap.Point(121.403541, 37.425849);
        var TXPointArr = [new BMap.Point(+point.lng, +point.lat)]; // point=  new BMap.Point(a[0],a[1])
        //腾讯坐标系 转化为 百度坐标系

        new BMap.Convertor().translate(TXPointArr, 0, 5, function (data) {
          var gc = new BMap.Geocoder();
          gc.getLocation(data.points[0], function () {});
        });
      } else {
        alert('failed' + this.getStatus());
      }
    }, {
      enableHighAccuracy: true
    });
  }
};
export default Position;