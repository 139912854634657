/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
export default {
  // 获取标题信息
  getSwiperInfo: function getSwiperInfo(params) {
    return http.post("".concat(base.dev, "/health/getTitleInfo"), params); // return jutils.delayVal({
    //   "code": "600",
    //   "msg": "操作成功",
    //   "data": {
    //     list:[
    //       {
    //         xh: 1,
    //         img: 'https://gss0.bdstatic.com/-4o3dSag_xI4khGkpoWK1HF6hhy/baike/c0%3Dbaike80%2C5%2C5%2C80%2C26/sign=c9ae9db23101213fdb3e468e358e5db4/9f510fb30f2442a79d8d54ced943ad4bd01302dd.jpg',
    //         title: '送你一朵fua'
    //       }, {
    //         xh:2,
    //         img: 'https://gss2.bdstatic.com/9fo3dSag_xI4khGkpoWK1HF6hhy/baike/c0%3Dbaike80%2C5%2C5%2C80%2C26/sign=d7e3abc328a446236ac7ad30f94b196b/574e9258d109b3de264335d1c4bf6c81810a4c8f.jpg',
    //         title: '送你一辆车'
    //       }
    //     ]
    //   }
    // })
  },
  // 总览页获取列表
  getMainList: function getMainList(params) {
    return http.post("".concat(base.dev, "/health/getMainList"), params); // return jutils.delayVal({
    //   "code": "600", "msg": "操作成功", "data": {
    //     "records": [
    //       {
    //         "xh": 1001,
    //         "label":'养生',
    //         "title":'谁说跑步减肥效果不好',
    //         "textSub": "我说的!我说的!我说的!我说的...", // 消息缩略版,为了减少带宽压力,这里由服务器截断
    //         "jlSj": "2020-02-12 15:16:58",
    //       },
    //       {
    //         "xh": 1002,
    //         "label":'小技巧',
    //         "title":'送你一朵fua',
    //         "textSub": "送你,送你,就送你!送你,送你,就...",
    //         "jlSj": "2020-02-12 15:16:58",
    //       },
    //     ], "totalRecord": 2, "totalPage": 1, "pageIndex": 1, "currenRecords": 2, "pageSize": 20
    //   }
    // });
  },
  // 获取消息详情
  getMsgInfo: function getMsgInfo(params) {
    return http.post("".concat(base.dev, "/health/getMsgInfo"), params); // return jutils.delayVal({
    //   "code": "600", "msg": "操作成功", "data": {
    //     "xh": 1001,
    //     "label": '养生',
    //     "title": '送你一朵fua',
    //     "imgTop": "https://gss2.bdstatic.com/9fo3dSag_xI4khGkpoWK1HF6hhy/baike/c0%3Dbaike80%2C5%2C5%2C80%2C26/sign=f6a7f16314d8bc3ed2050e98e3e2cd7b/86d6277f9e2f0708b21e6e59e424b899a901f254.jpg",
    //    "imgBottom": "https://gss2.bdstatic.com/-fo3dSag_xI4khGkpoWK1HF6hhy/baike/c0%3Dbaike80%2C5%2C5%2C80%2C26/sign=2043863b032442a7ba03f5f7b02ac62e/6159252dd42a28348a898ab357b5c9ea15cebf5f.jpg",
    //     "text": "送你,送你,就送你!送你,送你,就送你!送你,送你,就送你!送你,送你,就送你!送你,送你,就送你!送你,送你,就送你!送你,送你,就送你!送你,送你,就送你!送你,送你,就送你!",
    //     "jlSj": "2020-02-12 15:16:58",
    //   }
    // });
  }
};