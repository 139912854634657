/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
import loadsh from 'lodash'; // import VueI18n from 'vue-i18n'
// Vue.use(VueI18n);

var DEFAULT_LANG = 'zh';
var LOCALE_KEY = 'localeLanguage';
var index = {
  zh: require('./zh.json'),
  en: require('./en.json')
}; // const i18n = new VueI18n({
//     locale: DEFAULT_LANG,
//     messages: index,
//     silentTranslationWarn: true
// });

var I18n = function I18n() {
  var _this = this;

  this.locale = DEFAULT_LANG;

  this.$t = function (key) {
    return loadsh.get(index[_this.locale], key, '');
  };
};

var i18n = new I18n();
export var setup = function setup(lang) {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);

    if (index[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }

  window.localStorage.setItem(LOCALE_KEY, lang);
  Object.keys(index).forEach(function (lang) {
    document.body.classList.remove("lang-".concat(lang));
  });
  document.body.classList.add("lang-".concat(lang));
  document.body.setAttribute('lang', lang);
  Vue.config.lang = lang;
  i18n.locale = lang;
};
setup();
window.i18n = i18n;
Vue.prototype.$t = i18n.$t;