/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Org = {
  getOrgList: function getOrgList(depParentNo) {
    return http.get("".concat(base.dev, "/dep/selectDeptUserInfo"), {
      params: {
        depParentNo: depParentNo
      }
    });
  },
  // 获取部门
  getdepParentNo: function getdepParentNo(depParentNo) {
    return http.get("".concat(base.dev, "/dep/sel-dep-info"), {
      params: {
        depParentNo: depParentNo
      }
    });
  },
  // 获取该级人员采取分页形式
  getUserInfos: function getUserInfos(params) {
    return http.post("".concat(base.dev, "/dep/dep-user-info"), params);
  },
  getUserByOrg: function getUserByOrg(depJson) {
    return http.get("".concat(base.dev, "/userInfo/selectDeptUsers"), {
      params: {
        depJson: depJson
      }
    });
  },
  getUserInfo: function getUserInfo(userJson) {
    return http.get("".concat(base.dev, "/userInfo/selectUsersInfo"), {
      params: {
        userJson: userJson
      }
    });
  },
  getUserList: function getUserList(userInfo) {
    return http.get("".concat(base.dev, "/userInfo/selectUserList"), {
      params: {
        userInfo: userInfo
      }
    });
  }
};
export default Org;