/* this file is transformed by vux-loader */
/* eslint-disable */
import base from '../base';
import http from '../http';
var Repair = {
  getRepairDetail: function getRepairDetail(params) {
    return http.post("".concat(base.dev, "/attendance/selectAttendance"), params);
  },
  insertRepair: function insertRepair(params) {
    return http.post("".concat(base.dev, "/attendance/insertAttendance"), params);
  },
  deleteRepair: function deleteRepair(xh, userSerial, reason) {
    return http.delete("".concat(base.dev, "/attendance/deleteAttendance"), {
      params: {
        xh: xh,
        userSerial: userSerial,
        reason: reason
      }
    });
  },
  approvalRepair: function approvalRepair(params) {
    return http.post("".concat(base.dev, "/attendance/approvalAttendance"), params);
  }
};
export default Repair;